import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { blenderCss } from '@clds/blender';
import { Paper } from '@clds/paper';
import { Button } from '@cld/button-next';

export const ToastRoot = styled(Paper).attrs({ borderRadius: 'md', depth: 'sm' })`
  ${blenderCss.populateVariables};
  display: flex;
  align-items: center;
  padding: ${globalTheme.spacing.xxs} 0;
  min-height: 2.5rem;
  background-color: ${blenderCss.backgroundFromCssVariables};
  ${blenderCss.typographyFromCssVariables('md')};

  & > * {
    margin-right: ${globalTheme.spacing.sm};
    flex-shrink: 0;
  }
  & > :first-child {
    margin-left: ${globalTheme.spacing.lg};
  }
`;

export const CloseButton = styled(Button)`
  margin-left: ${globalTheme.spacing.xxs};
  flex-shrink: 0;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const ToastContent = styled.div`
  overflow: hidden; // needed if Tooltip.OverflowTrigger is inside
  flex-shrink: 1;
`;
