import { useEffect, useMemo, useRef } from 'react';
import { WebVTTParser } from 'webvtt-parser';

import { Environment, getEnvironment } from '@cld/console-apps-services';
import { DispatchAction } from '../actions';
import { State } from '../state';

// FIXME: use zod
interface PreviewResponseSchema {
  infoUrl: string;
  previewUrl: string;
  spriteImageUrl: string;
  spriteVTTUrl: string;
  downloadUrl: string;
}

// FIXME: extract injected client
const environment = getEnvironment();
export const PRODUCTION_CLIENT_URL = 'https://production-smart-ai-transformations-bff.cloudinary.com';
export const STAGING_CLIENT_URL = 'https://staging-smart-ai-transformations-bff.cloudinary.com';
export const apiClientUrl = environment === Environment.Production ? PRODUCTION_CLIENT_URL : STAGING_CLIENT_URL;

export const useVideoPreview = (dispatch: DispatchAction, clock: () => Date, videoPreview: State['videoPreview']) => {
  const parser = useMemo(() => new WebVTTParser(), []);
  const requestInProgress = useRef(false);

  useEffect(() => {
    void (async () => {
      if (videoPreview.status === 'pending' && !requestInProgress.current) {
        requestInProgress.current = true;

        try {
          const previewResponse = await fetch(`${apiClientUrl}/video-summarization/preview`, {
            body: JSON.stringify({ duration: videoPreview.lengthSeconds, publicId: videoPreview.publicId }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'post',
          });
          const previewData = (await previewResponse.json()) as PreviewResponseSchema;

          const spriteVttResponse = await fetch(previewData.spriteVTTUrl);
          const spriteVttData = await spriteVttResponse.text();

          const parsedVTT = parser.parse(spriteVttData);
          const spriteSheetData = parsedVTT.cues.map(({ text }) => {
            const spriteLocation = text.split('#xywh=')[1].split(',');

            return {
              x: parseInt(spriteLocation[0], 10),
              y: parseInt(spriteLocation[1], 10),
              width: parseInt(spriteLocation[2], 10),
              height: parseInt(spriteLocation[3], 10),
            };
          });

          dispatch({
            type: 'videoPreviewSucceeded',
            payload: {
              spriteSheetData,
              spriteSheetUrl: previewData.spriteImageUrl,
              timestamp: clock(),
              videoInfoUrl: previewData.infoUrl,
              videoUrl: previewData.previewUrl,
              downloadUrl: previewData.downloadUrl,
            },
          });
        } catch (error) {
          dispatch({ type: 'videoPreviewFailed', payload: { errorMessage: 'Unknown error occured' } });
        } finally {
          requestInProgress.current = false;
        }
      }
    })();
  }, [dispatch, clock, parser, videoPreview]);
};
