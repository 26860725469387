import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { FoundationContextProvider, SurfaceContextProvider } from '@clds/design-system-foundations';
import { buttonBlenderMapping } from './blenderMapping';
import { ButtonContent } from './ButtonContent';
import { buttonStyle } from './buttonStyle';
import { useButtonSurface, useFoundation } from './foundationMapping';
import { ButtonBlenderProps, ButtonProps, ButtonThemeProps } from './types';

export const ButtonRoot = styled.button<ButtonThemeProps & ButtonBlenderProps>`
  ${buttonBlenderMapping};
  ${buttonStyle};
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ size, shape = 'normal', variant, tone, isSelected = false, isDisabled = false, leftSlot, rightSlot, children, ...rest }, ref) => {
    const surface = useButtonSurface({ tone, variant });
    const foundation = useFoundation({ size });

    return (
      <FoundationContextProvider {...foundation.innerContextProps}>
        <SurfaceContextProvider {...surface.innerContextProps}>
          <ButtonRoot
            data-test="button"
            disabled={isDisabled}
            aria-disabled={isDisabled}
            shape={shape}
            isSelected={isSelected}
            {...surface.componentProps}
            {...foundation.componentProps}
            {...rest}
            ref={ref}
          >
            <ButtonContent size={foundation.componentProps.size} leftSlot={leftSlot} rightSlot={rightSlot}>
              {children}
            </ButtonContent>
          </ButtonRoot>
        </SurfaceContextProvider>
      </FoundationContextProvider>
    );
  }
);
