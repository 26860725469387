import styled from 'styled-components';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

interface StyledTransitionOverlayProps {
  timeout: CSSTransitionProps['timeout'];
}

export const StyledTransitionOverlay = styled.div<StyledTransitionOverlayProps>`
  transition: opacity ${(props) => props.timeout}ms;
  opacity: 0;

  &.transition-overlay-exit-active,
  &.transition-overlay-exit-done {
    opacity: 0;
  }
  &.transition-overlay-appear-done,
  &.transition-overlay-appear-active,
  &.transition-overlay-enter-active,
  &.transition-overlay-enter-done {
    opacity: 1;
  }
`;
