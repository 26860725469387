import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const LeftPane = styled.div`
  align-items: flex-end;
  background-color: ${globalTheme.palette.primary};
  display: flex;
  flex: 1.2 0 0px;
  flex-direction: column;
  padding: 80px 40px;
`;

const RightPane = styled.div`
  align-items: flex-start;
  background-color: ${globalTheme.palette.surface};
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  padding: 80px 50px;
  position: relative;
`;

export const PanesLayout = { Container, LeftPane, RightPane };
