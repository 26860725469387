import React, { FC } from 'react';
import { LeftSlotWrapper, RightSlotWrapper, TextWrapper } from './buttonStyle';
import { ButtonExtraProps, ButtonThemeProps } from './types';

export type ButtonContentProps = Pick<ButtonExtraProps, 'leftSlot' | 'rightSlot'> & Pick<ButtonThemeProps, 'size'>;

export const ButtonContent: FC<ButtonContentProps> = ({ rightSlot, children, leftSlot, size }) => (
  <>
    {leftSlot && (
      <LeftSlotWrapper data-test-specifier="left-slot" size={size}>
        {leftSlot}
      </LeftSlotWrapper>
    )}
    {children && (
      <TextWrapper data-test-specifier="text" size={size}>
        {children}
      </TextWrapper>
    )}
    {rightSlot && (
      <RightSlotWrapper data-test-specifier="right-slot" size={size}>
        {rightSlot}
      </RightSlotWrapper>
    )}
  </>
);
