import { useCallback, useMemo, useRef, useState } from 'react';

/**
 * Returns array of dom elements matching a selector that are descendant of given ref element.
 * You should use it only if you want to know what specific elements were rendered inside given component.
 * @param selector a selector that is passed to element.querySelectorAll function
 * @returns attributes the actual array of querySelectorAll result attributes
 * ref the reference that has to be passed to desired element
 * @example
 *   const {ref, result} = useQuerySelectorAll('[data-nested-menuitem]')
 *   console.log(result);
 *   return (
 *      <div ref={ref}>
 *        <MenuItem/>
 *        <MenuItem/>
 *        <Route path="/subroute">
 *           <MenuItem/>
 *           <MenuItem/>
 *           <MenuItem/>
 *        </Route>
 *        <MenuItem/>
 *     </div>
 *  );
 */
export const useQuerySelectorAll = (selector: string) => {
  const selectorRef = useRef(selector);
  selectorRef.current = selector;

  const [result, setResult] = useState<Element[]>([]);
  const elementRef = useRef<HTMLElement | null>();

  const { current: runQuery } = useRef(() => {
    /* istanbul ignore else  */
    if (elementRef.current) {
      const elements = Array.from(elementRef.current.querySelectorAll(selector));
      setResult(elements);
    }
  });

  const observer = useMemo(() => new MutationObserver(runQuery), [runQuery]);

  const ref = useCallback(
    (ref: HTMLElement | null) => {
      if (elementRef.current) {
        observer.disconnect();
      }
      elementRef.current = ref;
      if (ref) {
        runQuery();
        observer.observe(ref, { childList: true, subtree: true, attributes: true });
      }
    },
    [observer, runQuery]
  );

  return { ref, result };
};
