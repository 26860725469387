import React, { ComponentProps, FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import { TransitionActions } from 'react-transition-group/Transition';
import { asStyledComponent } from '@cld/component-enhancer';
import { StyledTransitionOverlay } from './Fade.styled';

export type FadeProps = ComponentProps<typeof CSSTransition> &
  TransitionActions & {
    cssTransitionProps?: ComponentProps<typeof CSSTransition>;
    className?: string;
  };

export const Fade = asStyledComponent<FC<FadeProps>>(
  ({ children, in: inProp, timeout = 250, mountOnEnter = true, unmountOnExit = true, appear = true, className, cssTransitionProps }) => {
    return (
      <CSSTransition
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
        appear={appear}
        in={inProp}
        timeout={timeout}
        classNames="transition-overlay"
        data-test="css-transition"
        className={className}
        {...cssTransitionProps}
      >
        <StyledTransitionOverlay timeout={timeout}>{children}</StyledTransitionOverlay>
      </CSSTransition>
    );
  }
);
