import React, { useCallback } from 'react';

enum Keys {
  up = 'ArrowUp',
  down = 'ArrowDown',
  left = 'ArrowLeft',
  right = 'ArrowRight',
  home = 'Home',
  end = 'End',
  enter = 'Enter',
  esc = 'Escape',
  space = 'Space',
}

export type KeyboardHandlers = Partial<{
  onUp: () => void;
  onDown: () => void;
  onLeft: () => void;
  onRight: () => void;
  onHome: () => void;
  onEnd: () => void;
  onEnter: () => void;
  onEscape: () => void;
  onSpace: () => void;
}>;

/**
 * A hook that binds keyboard event with keyboard handlers
 * @param onUp callback that will be called when event relates to up arrow key
 * @param onDown callback that will be called when event relates to down arrow key
 * @param onLeft callback that will be called when event relates to left arrow key
 * @param onRight callback that will be called when event relates to right arrow key
 * @param onHome callback that will be called when event relates to home key
 * @param onEnd callback that will be called when event relates to end key
 * @param onEscape callback that will be called when event relates to escape key
 * @param onEnter callback that will be called when event relates to enter key
 * @returns event handler that has to be added to focusable element that will listen for keyboard events
 */
export const useKeyboardEventHandler = ({ onUp, onDown, onLeft, onRight, onHome, onEnd, onEscape, onEnter, onSpace }: KeyboardHandlers) =>
  useCallback(
    (event: React.KeyboardEvent) => {
      const code = event.nativeEvent.code as Keys;
      const callback = {
        [Keys.down]: onDown,
        [Keys.up]: onUp,
        [Keys.left]: onLeft,
        [Keys.right]: onRight,
        [Keys.end]: onEnd,
        [Keys.home]: onHome,
        [Keys.enter]: onEnter,
        [Keys.esc]: onEscape,
        [Keys.space]: onSpace,
      }[code];

      if (callback) {
        callback();
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [onUp, onDown, onRight, onLeft, onHome, onEnd, onEscape, onEnter, onSpace]
  );
