import { SurveyRating } from '../components/survey/RatingSurvey';
import { PresetName } from './presets';

export interface State {
  lengthErrorMessage?: string;
  lengthValue: string;
  page: 'main' | 'results';
  pollingTextSeed: number;
  selectedSource?: { type: 'preset'; preset: PresetName } | { type: 'file'; file: File };
  videoDownload: { status: 'idle' } | { status: 'pending'; videoUrl: string };
  videoInfoFetch:
    | { status: 'idle' }
    | { status: 'pending'; videoInfoUrl: string }
    | { status: 'success'; cutSegments: Array<{ fromTimestamp: number; toTimestamp: number }>; relevanceHistogram: Array<number> }
    | { status: 'error'; errorMessage: string };
  videoPreview:
    | { status: 'idle' }
    | { status: 'pending'; lengthSeconds: number; publicId: string }
    | {
        status: 'success';
        originalLengthSeconds: number;
        spriteSheetData: Array<{ x: number; y: number; height: number; width: number }>;
        spriteSheetUrl: string;
        videoInfoUrl: string;
        videoUrl: string;
        downloadUrl: string;
      }
    | { status: 'error'; errorMessage: string };
  videoPolling:
    | { status: 'idle' }
    | { status: 'running'; lastPollTimestamp: Date; startTimestamp: Date; videoUrl: string }
    | { status: 'finished'; pollingTimeMilliseconds: number };
  videoUpload:
    | { status: 'idle' }
    | { status: 'pending'; file: File }
    | { status: 'success'; originalLengthSeconds: number; publicId: string }
    | { status: 'error' };
  rating: SurveyRating;
  videoOriginalDuration: null | number;
}

export const initialState: State = {
  lengthErrorMessage: undefined,
  lengthValue: '5',
  page: 'main',
  pollingTextSeed: 0,
  selectedSource: undefined,
  videoDownload: { status: 'idle' },
  videoInfoFetch: { status: 'idle' },
  videoPreview: { status: 'idle' },
  videoPolling: { status: 'idle' },
  videoUpload: { status: 'idle' },
  rating: null,
  videoOriginalDuration: null,
};
