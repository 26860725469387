import styled from 'styled-components';
import { SlideTransitionProps } from './Slide';

export const StyledTransitionOverlay = styled.div<SlideTransitionProps>`
  transition: transform ${({ timeout }) => timeout}ms;
  &.transition-overlay-appear,
  &.transition-overlay-enter,
  &.transition-overlay-exit-active {
    transform: ${({ transitionDirection, startPosition }) =>
      transitionDirection === 'horizontal' ? `translate(${startPosition}, 0px);` : `translate(0px, ${startPosition});`};
  }

  &.transition-overlay-appear-active,
  &.transition-overlay-enter-active,
  &.transition-overlay-appear-done,
  &.transition-overlay-enter-done {
    transform: ${({ transitionDirection, endPosition }) =>
      transitionDirection === 'horizontal' ? `translate(${endPosition}, 0px);` : `translate(0px, ${endPosition});`};
  }
`;
