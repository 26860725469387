import React, { useCallback, useEffect } from 'react';

export const usePlayOnHover = (videoRef: React.RefObject<HTMLVideoElement | null>, hoverTarget: React.RefObject<HTMLElement | null>) => {
  const pause = useCallback(() => {
    if (!videoRef.current?.paused) {
      videoRef.current?.pause();
    }
  }, [videoRef]);

  const resume = useCallback(() => {
    if (videoRef.current?.paused) {
      videoRef.current?.play();
    }
  }, [videoRef]);

  useEffect(() => {
    const savedRef = hoverTarget.current;
    savedRef?.addEventListener('mouseenter', resume);
    savedRef?.addEventListener('mouseleave', pause);

    return () => {
      savedRef?.removeEventListener('mouseenter', resume);
      savedRef?.removeEventListener('mouseleave', pause);
    };
  }, [pause, resume, hoverTarget]);
};
