import { createTheme, globalTheme } from '@clds/component-theme';

export const modalLayoutTheme = createTheme({
  layout: {
    spacing: {
      vertical: globalTheme.spacing.lg,
      horizontal: globalTheme.spacing.lg,
    },
    box: {
      shadow: globalTheme.shadow.sm,
      background: {
        color: globalTheme.palette.surface,
      },
      margin: globalTheme.spacing.md,
    },
    header: {
      background: {
        color: globalTheme.palette.surface,
      },
      accent: {
        background: {
          color: globalTheme.palette.surfaceAlt,
        },
        borderBottom: {
          width: '1px',
          color: globalTheme.palette.secondary,
        },
      },
    },
    content: {
      spacing: {
        bottom: globalTheme.spacing.xxl,
      },
      background: {
        color: globalTheme.palette.surface,
      },
    },
    footer: {
      background: {
        color: globalTheme.palette.surface,
      },
      accent: {
        background: {
          color: globalTheme.palette.surface,
        },
        borderTop: {
          width: '1px',
          color: globalTheme.palette.secondary,
        },
      },
    },
  },
  border: { radius: globalTheme.radius.lg },
});
