import { css } from 'styled-components';
import { iconSizeVariant } from './theme';

export default (props, { asObject } = {}) => {
  const width = props.size ? iconSizeVariant()?.(props) : '100%';
  const height = width;

  return !asObject
    ? css`
        width: ${width};
        height: ${height};
      `
    : { width, height };
};
