import { merge } from 'lodash';
import { createTheme, globalTheme } from '@clds/component-theme';

const { palette, radius } = globalTheme;


const primary = {
  background: {
    color: palette.primary,
    contrast: palette.contrastInvert,
  },
  focus: {
    color: palette.primary,
  },
  border: {
    radius: radius.lg,
  },
  typography: {
    type: 'contrastInvert',
  },
};

const secondary = {
  background: {
    color: palette.secondary,
    contrast: palette.contrastHigh,
  },
  focus: {
    color: palette.primary,
  },
  border: {
    radius: radius.lg,
  },
  typography: {
    type: 'highContrast',
  },
};

const error = {
  background: {
    color: palette.error,
    contrast: palette.contrastInvert,
  },
  focus: {
    color: palette.primary,
  },
  border: {
    radius: radius.lg,
  },
  typography: {
    type: 'contrastInvert',
  },
};

const warning = {
  background: {
    color: palette.warning,
    contrast: palette.contrastInvert,
  },
  focus: {
    color: palette.primary,
  },
  border: {
    radius: radius.lg,
  },
  typography: {
    type: 'contrastInvert',
  },
};

const success = {
  background: {
    color: palette.success,
    contrast: palette.contrastInvert,
  },
  focus: {
    color: palette.primary,
  },
  border: {
    radius: radius.lg,
  },
  typography: {
    type: 'contrastInvert',
  },
};

const link = {
  background: {
    color: palette.primary,
  },
  typography: {
    type: 'url',
  },
  border: {
    radius: radius.lg,
  },
  focus: {
    color: palette.primary,
  },
};

const menu = {
  background: {
    color: palette.contrastHigh,
  },
  typography: {
    type: 'lowContrast',
  },
  border: {
    radius: radius.lg,
  },
  focus: {
    color: palette.primary,
  },
};

const pillTheme = { border: { radius: '1000px' } };

export const buttonTheme = createTheme({
  pill: {
    primary: merge({}, primary, pillTheme),
    secondary: merge({}, secondary, pillTheme),
    error: merge({}, error, pillTheme),
    warning: merge({}, warning, pillTheme),
    success: merge({}, success, pillTheme),
    link: merge({}, link, pillTheme),
    menu: merge({}, menu, pillTheme),
  },
  solid: {
    primary,
    secondary,
    error,
    warning,
    success,
    link,
    menu,
  },
  group: {
    primary: {
      divider: {
        color: palette.surface,
        baseColor: palette.primary,
      },
    },
    secondary: {
      divider: {
        color: palette.contrastHigh,
        baseColor: palette.secondary,
      },
    },
  },
  split: {
    primary: {
      open: {
        accentColor: palette.backgroundAlt,
      },
    },
    secondary: {
      open: {
        accentColor: palette.contrastHigh,
      },
    },
  },
}, 'button');
