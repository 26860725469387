import React, { forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { focusRing } from '@clds/style-utils';
import { Paper, PaperDepth, PaperRadius } from '@clds/paper';
import { useListStyle } from './ListStyleProvider';
import { popoverMaxHeightVariant } from './theme';

export interface ListProps {
  borderRadius?: PaperRadius;
  depth?: PaperDepth;
  maxHeight?: 'md' | 'lg' | 'none';
}

const PaperWithFocusRing = styled(Paper) <ListProps>`
  max-height: ${popoverMaxHeightVariant((variant) => variant.maxHeight)};
  ${({ maxHeight }) =>
    (maxHeight !== undefined && maxHeight !== 'none') &&
    `
        overflow: auto;
    `}
  ${focusRing()}
`;

/**
 * Pure list component which provides list items container style
 */
export const ListPure = forwardRef<HTMLDivElement, PropsWithChildren<ListProps>>(({ children, borderRadius, depth, ...rest }, ref) => (
  <PaperWithFocusRing role="list" {...rest} borderRadius={borderRadius} depth={depth} ref={ref}>
    {children}
  </PaperWithFocusRing>
));

/**
 * List component that reads variant from context
 */
export const DefaultList = forwardRef<HTMLDivElement, PropsWithChildren<ListProps>>((props, ref) => {
  const listContext = useListStyle();

  return <ListPure {...listContext} {...props} ref={ref} />;
});
