import React, { createContext, FC, useContext } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { globalTheme, useMixin } from '@clds/component-theme';
import { typographyMixin, TypographyProps } from '@clds/typography';
import { Icon, IconProps } from '@cld/icon';
import { AvatarProps, Size } from './Avatar.types';
import { avatarSizeVariant, avatarTheme } from './theme';

export const SizeContext = createContext<Size | undefined>(undefined);

const AvatarBase = styled.div<AvatarProps>`
  border-radius: 100%;
  width: ${avatarSizeVariant((branch) => branch.width)};
  height: ${avatarSizeVariant((branch) => branch.width)};
  max-width: ${avatarSizeVariant((branch) => branch.width)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${avatarSizeVariant((branch) => typographyMixin(branch.typography as TypographyProps))};
  font-weight: 500;
  background-color: ${globalTheme.palette.secondary};
  border: solid 1px rgba(0, 0, 0, 0.1);
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
  ${({ textColor }) =>
    textColor &&
    css`
      border: 1px solid ${rgba(textColor, 0.1)};
      color: ${textColor};
    `};
  > img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
`;

/** Avatar component - can display 3 types of avatr: with image, with icon and with text and can be color customized */
const AvatarCompoundParent: FC<AvatarProps> = ({ children, size, rootProps, ...rest }) => (
  <AvatarBase data-test="avatar" size={size} {...rest} {...rootProps} >
    <SizeContext.Provider value={size}>{children}</SizeContext.Provider>
  </AvatarBase>
);

export const AvatarIcon: FC<Omit<IconProps, 'size'>> = (props) => {
  const size = useContext(SizeContext);
  if (size === undefined) {
    throw new Error('Avatar.Icon should be used inside Avatar');
  }
  const iconSize = useMixin(avatarTheme[size].iconSize) as Size;

  return <Icon size={iconSize} {...props} />;
};

export const Avatar = Object.assign(AvatarCompoundParent, { Icon: AvatarIcon });
