import React from 'react';
import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';
import { TextField } from '@clds/text-field';

interface LengthSelectionProps {
  errorMessage?: string;
  onChange: (newValue: string) => void;
  value: string;
}

const ErrorMessage = styled.p`
  color: ${globalTheme.palette.error};
  font-size: 16px;
  height: 20px;
`;

const Header = styled.p`
  color: ${globalTheme.palette.contrastLow};
  font-size: 16px;
  margin: 0 0 ${globalTheme.spacing.sm} 0;
`;

const FieldContainer = styled.div`
  align-items: center;
  display: flex;
`;

const InfoText = styled.p`
  color: ${globalTheme.palette.contrastLow};
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
`;

const LengthInput = styled(TextField)`
  width: 60px;

  > input {
    text-align: center;
  }
`;

const Root = styled.div`
  margin-bottom: 90px;
`;

export const LengthSelection = ({ errorMessage, onChange, value }: LengthSelectionProps) => (
  <Root>
    <Header>Video summary duration</Header>

    <FieldContainer>
      <LengthInput onChange={onChange} size="md" type="text" validationState={errorMessage ? 'error' : 'none'} value={value} />
      <InfoText>Seconds (Max 30)</InfoText>
    </FieldContainer>

    <ErrorMessage>{errorMessage}</ErrorMessage>
  </Root>
);
