import React, { useCallback, useEffect, useRef, useState } from 'react';

export type Duration = number | typeof NaN;

export const useVideoInfo = (videoNodeRef: React.RefObject<HTMLVideoElement>) => {
  const animationHandle = useRef<number | undefined>(undefined);
  const [currentTime, setCurrentTime] = useState<number | undefined>(undefined);
  const [duration, setDuration] = useState<Duration>(NaN);
  const [paused, setPaused] = useState(true);
  const [muted, setMuted] = useState(true);

  const updateValues = useCallback(() => {
    if (!videoNodeRef.current) {
      setCurrentTime(undefined);
      setDuration(NaN);
      animationHandle.current = requestAnimationFrame(updateValues);
      return;
    }

    setCurrentTime(videoNodeRef.current.currentTime);
    setDuration(videoNodeRef.current.duration);
    setPaused(videoNodeRef.current.paused);
    setMuted(videoNodeRef.current.muted);
    animationHandle.current = requestAnimationFrame(updateValues);
  }, [videoNodeRef]);

  useEffect(() => {
    animationHandle.current = requestAnimationFrame(updateValues);

    return () => {
      if (animationHandle.current) {
        cancelAnimationFrame(animationHandle.current);
      }
    };
  }, [updateValues]);

  return { currentTime, duration, paused, muted };
};
