import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';
// import { useSize } from './useSize';
import { useRenderLoop } from './useRenderLoop';

interface PlaybackTimelineProps {
  cutSegments: Array<{ fromTimestamp: number; toTimestamp: number }>;
  originalLengthSeconds: number;
  spriteSheetData: Array<{ x: number; y: number; width: number; height: number }>;
  spriteSheetUrl: string;
  videoNodeRef: React.RefObject<HTMLVideoElement>;
}

const playheadWidth = 2;
const summarizedBorderWidth = 3;

interface PlayheadProps {
  progressFraction: number;
}

const Playhead = styled.div.attrs<PlayheadProps>(({ progressFraction }) => ({
  style: {
    left: `${progressFraction * 100}%`,
  },
}))<PlayheadProps>`
  background-color: ${globalTheme.palette.success};
  height: 100%;
  position: absolute;
  top: 0;
  width: ${playheadWidth}px;

  &::after {
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: ${globalTheme.palette.success} transparent transparent transparent;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    top: 10px;
    transform: translateX(-50%);
    width: 0;
  }

  &::before {
    background-color: ${globalTheme.palette.success};
    content: '';
    height: 10px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 10px;
  }
`;

const Sprite = styled.div<{ spriteSheetUrl: string; x: number; y: number }>`
  background-image: url(${({ spriteSheetUrl }) => spriteSheetUrl});
  background-position: ${({ x }) => -x}px ${({ y }) => -y}px;
  background-repeat: no-repeat;
  flex: 1;
`;

const SummarizedTrack = styled.div<{
  endTimestamp: number;
  startTimestamp: number;
  totalLength: number;
}>`
  border: ${summarizedBorderWidth}px solid ${globalTheme.palette.successAlt};
  height: 100%;
  left: ${({ startTimestamp, totalLength }) => (startTimestamp / totalLength) * 100}%;
  overflow: hidden;
  position: absolute;
  width: ${({ endTimestamp, startTimestamp, totalLength }) => ((endTimestamp - startTimestamp) / totalLength) * 100}%;
`;

const Timeline = styled.div`
  align-self: stretch;
  display: flex;
  height: 60px;
  position: relative;
`;

export const PlaybackTimeline = ({ cutSegments, originalLengthSeconds, spriteSheetData, spriteSheetUrl, videoNodeRef }: PlaybackTimelineProps) => {
  const summarizedTrack = useRef<HTMLDivElement | null>(null);
  // const timelineSize = useSize(summarizedTrack);
  // const [ghostPlayheadPosition, setGhostPlayheadPosition] = useState<number | undefined>(undefined);
  const [videoProgress, setVideoProgress] = useState<number>(0);

  const updateProgress = useCallback(() => {
    const progress = videoNodeRef.current ? videoNodeRef.current.currentTime / videoNodeRef.current.duration : 0;
    setVideoProgress(progress);
  }, [videoNodeRef]);

  useRenderLoop(updateProgress);

  // FIXME: this needs cleaning up and extraction to react hook
  // for now leaving this as is, because there is not enough time to organize this after rewriting timeline to support multiple segments
  const segmentsLength = cutSegments.map(({ fromTimestamp, toTimestamp }) => Math.round((toTimestamp - fromTimestamp) * 100) / 100);
  const segmentsEndTimestamps = segmentsLength.reduce<Array<number>>((accumulator, current) => {
    const lastValue = accumulator[accumulator.length - 1] ?? 0;
    const newValue = Math.round((current + lastValue) * 100) / 100;

    return [...accumulator, newValue];
  }, []);
  const playheadSegmentResult = segmentsEndTimestamps.findIndex((endTimestamp) => videoProgress * (videoNodeRef.current?.duration ?? 0) < endTimestamp);
  const playheadSegment = playheadSegmentResult === -1 ? 0 : playheadSegmentResult;
  const previousSegmentEndTimestamp = playheadSegment === 0 ? 0 : segmentsEndTimestamps[playheadSegment - 1];
  const progressWithinSegment =
    playheadSegmentResult === -1 ? 0 : ((videoNodeRef.current?.currentTime ?? 0) - previousSegmentEndTimestamp) / segmentsLength[playheadSegment];

  // FIXME: logic for seeking using timeline, commented out when added multiple segments support
  // const handleTrackClick = (event: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
  //   const boundingRect = event.currentTarget.getBoundingClientRect();

  //   if (boundingRect && videoNodeRef.current) {
  //     // FIXME: account for border
  //     const leftDistanceFraction = (event.nativeEvent.clientX - boundingRect.left) / boundingRect.width;
  //     videoNodeRef.current.currentTime = leftDistanceFraction * videoNodeRef.current.duration;
  //   }
  // };

  // FIXME: logic for seeking using timeline, commented out when added multiple segments support
  // const handleTrackMouseMove = (event: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
  //   const boundingRect = event.currentTarget.getBoundingClientRect();

  //   if (boundingRect) {
  //     // FIXME: account for border
  //     const leftDistanceFraction = (event.nativeEvent.clientX - boundingRect.left) / boundingRect.width;
  //     setGhostPlayheadPosition(leftDistanceFraction);
  //   }
  // };

  // const handleTrackMouseLeave = () => {
  //   setGhostPlayheadPosition(undefined);
  // };

  return (
    <Timeline>
      {spriteSheetData.map(({ x, y }, index) => (
        <Sprite key={index} spriteSheetUrl={spriteSheetUrl} x={x} y={y} />
      ))}

      {cutSegments.map(({ fromTimestamp, toTimestamp }, index) => (
        <SummarizedTrack
          endTimestamp={toTimestamp}
          key={index}
          // onClick={handleTrackClick}
          // onMouseMove={handleTrackMouseMove}
          // onMouseLeave={handleTrackMouseLeave}
          ref={summarizedTrack}
          startTimestamp={fromTimestamp}
          totalLength={originalLengthSeconds}
        >
          {index === playheadSegment && <Playhead progressFraction={progressWithinSegment} />}
          {/* {ghostPlayheadPosition !== undefined &&
            <Playhead isGhost progressFraction={ghostPlayheadPosition} trackWidth={timelineSize.width ?? 0} />} */}
        </SummarizedTrack>
      ))}
    </Timeline>
  );
};
