import React from 'react';
import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';
import { ProgressButton } from '@cld/button-next';

interface SubmitButtonProps {
  isDisabled: boolean;
  isLoading: boolean;
  onClick(): void;
}

const ButtonText = styled.span`
  font-size: 24px;
`;

const DesktopInfoText = styled.p`
  color: ${globalTheme.palette.contrastLow};
  font-size: 12px;
  margin: 10px 0 0 0;
  text-align: center;
`;

const Root = styled.div`
  margin-bottom: 24px;
`;

const StyledButton = styled(ProgressButton)`
  padding: 24px 58px;
`;

export const SubmitButton = ({ isDisabled, isLoading, onClick }: SubmitButtonProps) => (
  <Root>
    <StyledButton isDisabled={isDisabled} onClick={onClick} showSpinner={isLoading}>
      <ButtonText>Try it now</ButtonText>
    </StyledButton>

    <DesktopInfoText>Only on desktop devices</DesktopInfoText>
  </Root>
);
