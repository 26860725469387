import React from 'react';
import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';
import { CloudinaryLogo } from '@clds/icon';
import { LEAVE_US_MESSAGE_HREF } from '../mainPage/BrandBar';
import { SectionedEventData } from '../state/effects/useAnalytics';

const Content = styled.div`
  align-items: center;
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
  padding: 15px 0 15px 0;
`;

const Brand = styled.span`
  color: ${globalTheme.palette.contrastHigh};
  font-size: 20px;
  font-weight: 500;
`;

const Labs = styled.span`
  color: ${globalTheme.palette.contrastHigh};
  font-size: 32px;
  font-weight: 200;
  letter-spacing: 2.5px;
  margin-left: 10px;
`;

const Logo = styled(CloudinaryLogo)`
  color: ${globalTheme.palette.contrastHigh};
  margin-right: ${globalTheme.spacing.xxs};
`;

const Root = styled.header`
  background-color: ${globalTheme.palette.surfaceAlt};
  border-bottom: 2px solid ${globalTheme.palette.background};
  z-index: 1;
`;

const SignUpText = styled.a`
  color: ${globalTheme.palette.contrastHigh};
  font-size: 18px;
  font-weight: 800;
  margin-left: auto;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface BrandBarProps {
  resetState(): void;
  track: (eventData: SectionedEventData) => void
}

export const BrandBar = ({ resetState, track }: BrandBarProps) => (
  <Root>
    <Content>
      <TitleContainer onClick={resetState}>
        <Logo size="xl" />
        <Brand>Cloudinary</Brand>
        <Labs>LABS</Labs>
      </TitleContainer>
      <SignUpText
        href={LEAVE_US_MESSAGE_HREF}
        target="_blank"
        onClick={() => track({ event: 'contact_navigate', eventDescription: 'click talk to us/leave a message' })}
      >
        Leave us a message
      </SignUpText>
    </Content>
  </Root>
);
