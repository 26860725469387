import React from 'react';
import { ModalOverlayProps } from './ModalOverlay';

type FocusableElement = HTMLInputElement | HTMLAnchorElement | HTMLSelectElement | HTMLTextAreaElement;
const focusableElementsSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export const focusBackwards = (event: KeyboardEvent, firstFocusableElement: FocusableElement, lastFocusableElement: FocusableElement) => {
  if (document.activeElement === firstFocusableElement) {
    lastFocusableElement.focus();
    event.preventDefault();
  }
};

export const focusForward = (event: KeyboardEvent, firstFocusableElement: FocusableElement, lastFocusableElement: FocusableElement) => {
  if (document.activeElement === lastFocusableElement) {
    firstFocusableElement.focus();
    event.preventDefault();
  }
};

export const handleExternalFocus = (event: KeyboardEvent, modal: React.RefObject<HTMLDivElement>, firstFocusableElement: FocusableElement) => {
  if (modal.current && !modal.current.contains(event.target as HTMLElement)) {
    firstFocusableElement.focus();
    event.preventDefault();
  }
};

export const onOutsideModalClick = (
  event: MouseEvent,
  modal: React.RefObject<HTMLDivElement>,
  onClose: ModalOverlayProps['onClose'],
  closeOnOutsideClick: ModalOverlayProps['closeOnOutsideClick']
): void => {
  if (!closeOnOutsideClick) {
    return;
  }

  const isNonPrimaryButtonPressed = event.button !== undefined && event.button !== 0;

  if (isNonPrimaryButtonPressed || !modal.current || modal.current.contains(event.target as HTMLElement)) {
    return;
  }

  onClose();
};

export const interceptFocus = (event: KeyboardEvent, modal: React.RefObject<HTMLDivElement>): void => {
  const { firstFocusableElement, focusableContent, lastFocusableElement } = getFocusableContent(modal);
  if (focusableContent.length === 0) {
    return;
  }

  // if shift key pressed for shift + tab combination
  if (event.shiftKey) {
    focusBackwards(event, firstFocusableElement, lastFocusableElement);
    return;
  }

  // if last focusable element is focused then focus first focusable element after pressing tab
  focusForward(event, firstFocusableElement, lastFocusableElement);

  // If afterwards external element is focused, refocus it to the first focusable element in modal
  handleExternalFocus(event, modal, firstFocusableElement);
};

const getFocusableContent = (modal: React.RefObject<HTMLDivElement>) => {
  const focusableContent = (modal.current?.querySelectorAll(focusableElementsSelector) || []) as FocusableElement[];
  const firstFocusableElement = focusableContent[0];
  const lastFocusableElement = focusableContent[focusableContent.length - 1];

  return {
    focusableContent,
    firstFocusableElement,
    lastFocusableElement,
  };
};
