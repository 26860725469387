export const sendAnalytics = ({
  additionalInfo,
  eventDescription,
  event,
  productName,
  mainScreenName,
}: {
  additionalInfo?: string;
  eventDescription: string;
  event: string;
  mainScreenName: string;
  productName: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
  (window as any).dataLayer.push({
    event_description: eventDescription,
    event: event,
    main_screen_name: mainScreenName,
    product_name: productName,
    ...(additionalInfo ? { additional_info: additionalInfo } : {}),
  });
};
