import { useMemo } from 'react';

export interface EventData {
  additionalInfo?: string;
  eventDescription: string;
  event: string;
  mainScreenName: string;
  productName: string;
}

export type SectionedEventData = Omit<EventData, 'productName' | 'mainScreenName'>
export type ProductEventData = Omit<EventData, 'productName'>

type Analytics = (eventData: { additionalInfo?: string; eventDescription: string; event: string; mainScreenName: string; productName: string }) => void

export const generateTrackFn = (track: Analytics) => (eventData:ProductEventData) => {
  track({ ...eventData, productName: 'video summarization' });
};
export const generateTrackShortVideoScreen = (trackSmartAi: (eventData: ProductEventData) => void) => (eventData: SectionedEventData) => {
  trackSmartAi({ ...eventData, mainScreenName: 'short video result screen' });
};
export const generateTrackWelcomeScreen = (trackSmartAi: (eventData: ProductEventData) => void) => (eventData: SectionedEventData) => {
  trackSmartAi({ ...eventData, mainScreenName: 'welcome screen' });
};

export const useAnalytics = (track: Analytics) => {
  const trackSmartAi = useMemo(() => generateTrackFn(track), [track]);
  const trackWelcomeScreen = useMemo(() => generateTrackWelcomeScreen(trackSmartAi), [trackSmartAi]);
  const trackShortVideoScreen = useMemo(() => generateTrackShortVideoScreen(trackSmartAi), [trackSmartAi]);

  return { trackWelcomeScreen, trackShortVideoScreen };
};
