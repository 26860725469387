import React, { forwardRef, PropsWithChildren, Ref } from 'react';
import { useMergeRefs } from 'use-callback-ref';
import { ImplicitMixedControlProps, ImplicitTraceableMixedControlProps } from '@clds/common-definitions';
import { ItemStyleProps } from '@clds/item';
import { DefaultList, ListStyleProvider, Unselect } from '@cld/compound-components';
import { useMenu } from './headless/useMenu';
import { useMenuState } from './headless/useMenuState';
import { MenuProvider } from './MenuProvider';

export type MenuStateProps = ImplicitMixedControlProps<'focusedId', string | Unselect> &
  ImplicitTraceableMixedControlProps<'selectedId', string | Unselect>;

export type MenuProps = {
  /** Options to configure how to display items  */
  variant?: Partial<ItemStyleProps>;
  /** Called every time that new menu item is selected */
  onSelect?: (id: string | Unselect) => void;
  maxHeight?: 'md' | 'lg' | 'none';
} & MenuStateProps;

/**
 * Component that manages list of items and controls navigation and focus state.
 * It doesn't control selection! This is only applicable for components that compose it
 * This component is useful when used together with other components, for example with Popover or Drawer
 */
export const Menu = forwardRef<HTMLElement, PropsWithChildren<MenuProps>>(
  ({ children, variant, defaultFocusedId, focusedId, onFocusedIdChange, defaultSelectedId, selectedId, onSelect, maxHeight, ...rest }, ref) => {
    const state = useMenuState({ defaultFocusedId, focusedId, onFocusedIdChange, defaultSelectedId, selectedId, onSelect });
    const menu = useMenu(state);
    const mergedRef = useMergeRefs([menu.descendantsParentRef, ref]); // TODO maybe ref should go after state to useMenu

    return (
      <MenuProvider {...menu.menuProviderProps}>
        <ListStyleProvider {...variant}>
          <DefaultList {...menu.focusableProps} ref={mergedRef as Ref<HTMLDivElement>} maxHeight={maxHeight} {...rest}>
            {children}
          </DefaultList>
        </ListStyleProvider>
      </MenuProvider>
    );
  }
);
