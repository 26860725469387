import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { mix } from 'polished';
import { combineValues } from '@clds/component-theme';
import { Button } from './Button';
import { buttonTheme } from './buttonTheme';

const separatorColor = ({ variant }) =>
  combineValues(buttonTheme.group[variant]?.divider?.color, buttonTheme.group[variant]?.divider?.baseColor, (color, baseColor) =>
    mix(0.12, color, baseColor)
  );

const separator = css`
  ::after {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    height: 100%;
    width: 1px;
    background-color: ${separatorColor};
  }
`;

const firstButton = `
  ${Button}:first-child:not(:only-child),
  & > *:first-child:not(:only-child) ${Button}
`;
const middleButtons = `
  ${Button}:not(:first-child):not(:last-child),
  & > *:not(:first-child):not(:last-child) ${Button}
`;
const lastButton = `
  ${Button}:last-child:not(:first-child),
  & > *:last-child:not(:first-child) ${Button}
`;

export const ButtonGroup = styled.div`
  display: inline-flex;

  // Position focus ring properly
  ${Button}:focus {
    z-index: 1;
    &:before {
      z-index: 2;
    }
  }

  ${firstButton} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    :focus:not(:active) {
      &::before {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  ${middleButtons} {
    border-radius: 0;

    ${separator}

    &:focus:not(:active) {
      &::before {
        border-radius: 0;
      }
    }
  }

  ${lastButton} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    ${separator}

    &:focus:not(:active):not(:first-child) {
      &::before {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
`;

ButtonGroup.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']).isRequired,
};

ButtonGroup.defaultPorps = {
  variant: 'primary',
};
