import styled from 'styled-components';

const SURVEY_WIDTH = 309;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: calc(1000px + 2 * ${SURVEY_WIDTH}px);
  width: 100%;
`;

const CenterPane = styled.div`
  flex-grow: 2;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  align-items: center;
`;

const RightPane = styled.div`
  flex-grow: 2;
  min-width: ${SURVEY_WIDTH}px;
`;

const LeftPane = styled.div`
  flex-grow: 1;
  flex-shrink: 2;
  width: ${SURVEY_WIDTH}px;
`;

export const Layout = { Container, Content, CenterPane, RightPane, LeftPane };
