import React from 'react';
import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';
import { PresetName, presets } from '../../state/presets';
import { State } from '../../state/state';
import { SectionedEventData } from '../../state/effects/useAnalytics';
import { PresetEntry } from './PresetEntry';
import { FileSelection } from './FileSelection';

interface SourceSelectionProps {
  onFileSelect(file: File): void;
  onSelectPreset(presetName: PresetName): void;
  onOriginalDurationChange(duration: number): void;
  selectedFile?: File;
  selectedPreset?: PresetName;
  videoUpload: State['videoUpload'];
  track: (eventData: SectionedEventData) => void;
}

const GuidanceText = styled.p`
  color: ${globalTheme.palette.contrastLow};
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 20px 0;
`;

const PresetsList = styled.div`
  display: flex;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const SourceSelection = ({
  onFileSelect,
  onSelectPreset,
  onOriginalDurationChange,
  selectedFile,
  selectedPreset,
  videoUpload,
  track,
}: SourceSelectionProps) => (
  <Root>
    <GuidanceText>Try one of these videos:</GuidanceText>

    <PresetsList>
      {Object.keys(presets).map((name) => {
        const { label, previewThumbnailUrl, originalLengthSeconds } = presets[name as keyof typeof presets];

        return (
          <PresetEntry
            videoUrl={previewThumbnailUrl}
            isActive={selectedPreset === name}
            key={name}
            label={label}
            onClick={() => {
              onSelectPreset(name as keyof typeof presets);
              onOriginalDurationChange(originalLengthSeconds);
            }}
          />
        );
      })}

      <FileSelection
        onSelect={onFileSelect}
        onOriginalDurationChange={onOriginalDurationChange}
        selectedFile={selectedFile}
        videoUpload={videoUpload}
        track={track}
      />
    </PresetsList>
  </Root>
);
