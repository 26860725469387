import { createTheme, globalTheme } from '@clds/component-theme';

export const skeletonTheme = createTheme(
  {
    base: globalTheme.palette.background,
    lighten: -0.05, // number from -1 <-> 1
  },
  'skeleton'
);

