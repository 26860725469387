import { useEffect } from 'react';

import { DispatchAction } from '../actions';
import { State } from '../state';

export const useVideoDownload = (dispatch: DispatchAction, videoDownload: State['videoDownload']) => {
  useEffect(() => {
    if (videoDownload.status === 'pending') {
      window.location.assign(videoDownload.videoUrl);

      dispatch({ type: 'downloadStarted', payload: {} });
    }
  }, [dispatch, videoDownload]);
};
