import React from 'react';
import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';

const dots = 24;
const dotSize = 4;
const dotSpacing = 6;
const dotsPerRow = 6;
const dotArraySize = dotSize * dotsPerRow + dotSpacing * (dotsPerRow - 1);

const Container = styled.div`
  margin-bottom: 50px;
  position: relative;
`;
const ContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
`;

const Dot = styled.div<{ columnIndex: number; rowIndex: number }>`
  background-color: ${globalTheme.palette.dataVis.accent2};
  border-radius: 50%;
  height: ${dotSize}px;
  left: ${({ columnIndex, rowIndex }) => -(dotArraySize / 2) + columnIndex * (dotSize + dotSpacing) - rowIndex * (dotSize + dotSpacing)}px;
  position: absolute;
  top: ${({ rowIndex }) => -(dotArraySize / 2) + rowIndex * (dotSize + dotSpacing)}px;
  width: ${dotSize}px;
`;

const DotsAnchor = styled.div`
  left: -5px;
  position: absolute;
  top: 20px;
`;
const DotsAnchorMobile = styled.div`
  left: 25px;
  position: absolute;
  top: 38px;
`;

const Heading = styled.h2`
  color: ${globalTheme.palette.surface};
  font-size: 83px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 ${globalTheme.spacing.sm} 0;
  max-width: 700px;
`;
const HeadingMobile = styled(Heading)`
  font-weight: 900;
  font-size: 42px;
  padding: 15px;
  text-align: center;
`;

const SmallText = styled.p`
  color: ${globalTheme.palette.surface};
  font-size: 24px;
  line-height: 1.5;
  margin: 0;
  max-width: 550px;
`;
const SmallTextMobile = styled(SmallText)`
  font-weight: 500;
  text-align: center;
  max-width: unset;
`;

export const Title = () => (
  <Container>
    <Heading>Easily create engaging video summaries</Heading>
    <SmallText>Automatically summarize long videos to engage your audience with bitesize clips</SmallText>

    <DotsAnchor>
      {Array(dots)
        .fill(null)
        .map((_, index) => (
          <Dot columnIndex={index % dotsPerRow} key={index} rowIndex={Math.floor(index / dotsPerRow)} />
        ))}
    </DotsAnchor>
  </Container>
);

export const TitleMobile = () => (
  <ContainerMobile>
    <HeadingMobile>Easily create engaging video summaries</HeadingMobile>
    <SmallTextMobile>Automatically summarize long videos to engage your audience with bitesize clips</SmallTextMobile>

    <DotsAnchorMobile>
      {Array(dots)
        .fill(null)
        .map((_, index) => (
          <Dot columnIndex={index % dotsPerRow} key={index} rowIndex={Math.floor(index / dotsPerRow)} />
        ))}
    </DotsAnchorMobile>
  </ContainerMobile>
);
