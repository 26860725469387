import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Survey, SurveyProps } from '../components/survey/Survey';
import { Download, DownloadProps } from '../components/download/Download';

const delayAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  background-color: ${globalTheme.palette.surfaceAlt};
  color: ${globalTheme.palette.contrastHigh};
  padding: 24px;
  width: 100%;
  opacity: 0;
  animation: ${delayAnimation} 300ms ease-in forwards;
`;

const delayInSeconds = 2;

export interface ResultsActionsProps extends SurveyProps, DownloadProps {}

export const ResultsActions = (props: ResultsActionsProps) => {
  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setShowActions(true), delayInSeconds * 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  return showActions ? (
    <Container>
      <Survey {...props} />
      <Download {...props} />
    </Container>
  ) : null;
};
