import React, { useMemo, useRef } from 'react';
import { HorizontalGridLines, LineSeries, VerticalGridLines, XYPlot } from 'react-vis';
import styled from 'styled-components';

import { globalTheme, useMixin } from '@clds/component-theme';
import { useSize } from './useSize';

interface RelevanceHistogramProps {
  data: Array<number>;
}

const chartBorderWidth = 1;

const Chart = styled.div`
  background-color: ${globalTheme.palette.surface};
`;

const Container = styled.div`
  margin-top: 32px;
  width: 100%;
`;

const Heading = styled.h4`
  color: ${globalTheme.palette.contrastHigh};
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px 0;
`;

export const RelevanceHistogram = ({ data }: RelevanceHistogramProps) => {
  const chart = useRef<HTMLDivElement | null>(null);
  const chartSize = useSize(chart);
  const chartLineColor = useMixin(globalTheme.palette.dataVis.accent8);

  const availableWidth = chartSize.width ? chartSize.width - chartBorderWidth * 2 : 0;
  const chartData = useMemo(() => data.map((value, index) => ({ x: index, y: value })), [data]);

  return (
    <Container>
      <Heading>Most interesting (identified by Cloudinary)</Heading>

      <Chart ref={chart}>
        <XYPlot height={60} margin={{ left: 0, right: 0, top: 10, bottom: 0 }} width={availableWidth}>
          <VerticalGridLines />
          <HorizontalGridLines />
          <LineSeries color={chartLineColor} curve="curveNatural" data={chartData} style={{ fill: 'none' }} />
        </XYPlot>
      </Chart>
    </Container>
  );
};
