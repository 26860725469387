import React, { ComponentProps, ComponentType, FC, isValidElement, useMemo } from 'react';
import styled from 'styled-components';

let FormattedMessage: unknown = null;

try {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires
  FormattedMessage = require('react-intl').FormattedMessage;
} catch (e) {
  if (!(e instanceof Error) || !~e.message.indexOf('Cannot find')) {
    throw e;
  }
}

export const useWrapTextChildren = (children?: React.ReactNode) => {
  return useMemo(
    () =>
      React.Children.toArray(children).map((child, idx) => {
        const isFormattedMessage = isValidElement(child) && child.type === FormattedMessage;
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return typeof child === 'string' || isFormattedMessage ? <TextWrap key={`${child}-${idx}`}>{child}</TextWrap> : child;
      }),
    [children]
  );
};

export const WrapTextChildren: FC = ({ children }) => <>{useWrapTextChildren(children)}</>;

export const withTextWrap = <Type extends ComponentType>(WrappedComponent: Type | string) => (props: ComponentProps<Type>) => (
  <TextWrap>
    <WrappedComponent {...props} />
  </TextWrap>
);

export const TextWrap = styled.span``;
