import React, { useRef } from 'react';
import { SurveyRating, UserRating } from '../components/survey/RatingSurvey';
import { State } from '../state/state';
import { SectionedEventData } from '../state/effects/useAnalytics';
import { VideoPreview } from './VideoPreview';
import { Layout } from './Layout';
import { PlaybackTimeline } from './PlaybackTimeline';
import { VideoProcessingInfo } from './VideoProcessingInfo';
import { TransformationNameBar } from './TransformationNameBar';
import { BrandBar } from './BrandBar';
import { RelevanceHistogram } from './RelevanceHistogram';
import { ResultsActions } from './ResultsActions';
import { SignupButton } from './SignupButton';

interface ResultsPageProps {
  onVideoDownloadClick(): void;
  originalLengthSeconds: number;
  pollingTextSeed: number;
  videoPreview: State['videoPreview'];
  videoPollingStatus: State['videoPolling']['status'];
  videoInfoFetch: State['videoInfoFetch'];
  rating: SurveyRating;
  onRatingSelected: (rating: UserRating) => void;
  desiredLengthValue: string;
  resetState(): void;
  track: (eventData: SectionedEventData) => void;
}

export const ResultsPage = ({
  originalLengthSeconds,
  onVideoDownloadClick,
  pollingTextSeed,
  videoInfoFetch,
  videoPreview,
  videoPollingStatus,
  rating,
  onRatingSelected,
  desiredLengthValue,
  resetState,
  track,
}: ResultsPageProps) => {
  const videoNode = useRef<HTMLVideoElement>(null);
  const isVideoPreviewReady = videoPollingStatus === 'finished' && videoPreview.status === 'success';
  const isVideoInfoReady = videoInfoFetch.status === 'success';

  return (
    <>
      <BrandBar resetState={resetState} track={track} />

      <Layout.Container>
        <TransformationNameBar />

        <Layout.Content>{!isVideoPreviewReady && <VideoProcessingInfo textSeed={pollingTextSeed} />}</Layout.Content>

        <Layout.Content>
          <Layout.LeftPane />
          <Layout.CenterPane>
            {isVideoPreviewReady && (
              <VideoPreview
                desiredLengthValue={desiredLengthValue}
                originalLengthSeconds={originalLengthSeconds}
                videoNodeRef={videoNode}
                videoUrl={videoPreview.videoUrl}
                track={track}
              />
            )}

            {isVideoPreviewReady && isVideoInfoReady && (
              <PlaybackTimeline
                cutSegments={videoInfoFetch.cutSegments}
                originalLengthSeconds={originalLengthSeconds}
                spriteSheetData={videoPreview.spriteSheetData}
                spriteSheetUrl={videoPreview.spriteSheetUrl}
                videoNodeRef={videoNode}
              />
            )}

            {isVideoInfoReady && <RelevanceHistogram data={videoInfoFetch.relevanceHistogram} />}
          </Layout.CenterPane>
          <Layout.RightPane>
            {isVideoPreviewReady && <ResultsActions rating={rating} onRatingSelected={onRatingSelected} onVideoDownloadClick={onVideoDownloadClick} />}
            {isVideoPreviewReady && <SignupButton track={track} />}
          </Layout.RightPane>
        </Layout.Content>
      </Layout.Container>
    </>
  );
};
