import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { SectionedEventData } from '../state/effects/useAnalytics';

const Link = styled.a`
  color: ${globalTheme.palette.contrastHigh};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  position: fixed;
  bottom: 25px;
  right: 120px;
`;

export const SignupButton = ({ track }: { track: (eventData: SectionedEventData) => void }) => (
  <Link
    href="https://cloudinary.com/users/register_free"
    rel="noreferrer noopener"
    target="_blank"
    onClick={() => track({ event: 'signup_navigate', eventDescription: 'click free sign up to cloudinary' })}
  >
    Free sign up to Cloudinary
  </Link>
);
