import { differenceInMilliseconds } from 'date-fns/fp';
import { useCallback, useEffect, useRef } from 'react';

import { DispatchAction } from '../actions';
import { State } from '../state';

const pollingIntervalMilliseconds = 5000;

export const useVideoPolling = (dispatch: DispatchAction, clock: () => Date, videoPolling: State['videoPolling']) => {
  const pollInProgress = useRef(false);

  const poll = useCallback(() => {
    if (videoPolling.status === 'running' && !pollInProgress.current) {
      const now = clock();
      const nextPollRequired =
        !videoPolling.lastPollTimestamp || differenceInMilliseconds(videoPolling.lastPollTimestamp, now) > pollingIntervalMilliseconds;

      if (nextPollRequired) {
        pollInProgress.current = true;

        void fetch(videoPolling.videoUrl, {}).then((response) => {
          if (response.status === 423) {
            dispatch({
              type: 'videoPollingTicked',
              payload: { timestamp: clock() },
            });
          } else {
            dispatch({
              type: 'videoPollingFinished',
              payload: { timestamp: clock() },
            });
          }

          pollInProgress.current = false;
        });
      }
    }
  }, [clock, dispatch, videoPolling]);

  useEffect(() => {
    let pollingIntervalId: number | undefined;

    if (videoPolling.status === 'running') {
      pollingIntervalId = window.setInterval(poll, pollingIntervalMilliseconds);
    }

    return () => {
      if (pollingIntervalId) {
        window.clearInterval(pollingIntervalId);
      }
    };
  }, [poll, videoPolling.status]);
};
