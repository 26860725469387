import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { FoundationContextProvider, SurfaceContextProvider } from '@clds/design-system-foundations';
import { buttonBlenderMapping } from './blenderMapping';
import { ButtonContent } from './ButtonContent';
import { buttonStyle } from './buttonStyle';
import { useFoundation, useLinkSurface } from './foundationMapping';
import { ButtonThemeProps, ButtonBlenderProps, LinkButtonProps } from './types';

export const LinkButtonRoot = styled.a<ButtonThemeProps & ButtonBlenderProps>`
  ${buttonBlenderMapping};
  ${buttonStyle};
  &,
  &:hover {
    text-decoration: none;
  }
`;

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  ({ size, shape = 'normal', variant, tone, isSelected = false, isDisabled = false, leftSlot, rightSlot, children, tabIndex, ...rest }, ref) => {
    const surface = useLinkSurface({ tone, variant });
    const foundation = useFoundation({ size });

    return (
      <FoundationContextProvider {...foundation.innerContextProps}>
        <SurfaceContextProvider {...surface.innerContextProps}>
          <LinkButtonRoot
            data-test="link-button"
            aria-disabled={isDisabled}
            shape={shape}
            isSelected={isSelected}
            tabIndex={isDisabled ? -1 : tabIndex}
            {...surface.componentProps}
            {...foundation.componentProps}
            {...rest}
            ref={ref}
          >
            <ButtonContent size={foundation.componentProps.size} leftSlot={leftSlot} rightSlot={rightSlot}>
              {children}
            </ButtonContent>
          </LinkButtonRoot>
        </SurfaceContextProvider>
      </FoundationContextProvider>
    );
  }
);
