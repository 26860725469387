import { useLayoutEffect } from 'react';
import useControlledState from '@quid/react-use-controlled-state';
import { unselect, Unselect } from '@cld/compound-components';

export interface MenuStateProps {
  defaultFocusedId?: string | Unselect;
  focusedId?: string | Unselect;
  onFocusedIdChange?: (id: string | Unselect) => void;
  defaultSelectedId?: string | Unselect;
  selectedId?: string | Unselect;
  onSelect?: (id: string | Unselect) => void;
}

export type MenuState = ReturnType<typeof useMenuState>;

const useSyncOnChange = (observerValue: string | typeof unselect, syncCallback: (value: string | Unselect) => void) => {
  useLayoutEffect(() => {
    if (observerValue !== undefined && observerValue !== unselect) {
      syncCallback(observerValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerValue]);
};

export const useMenuState = (props: MenuStateProps) => {
  const [focusedId, setFocusedId] = useControlledState<string | Unselect>(
    props.defaultFocusedId === undefined && props.focusedId === undefined ? unselect : props.defaultFocusedId,
    props.focusedId,
    props.onFocusedIdChange
  );

  const [selectedId, setSelectedId] = useControlledState<string | Unselect>(
    props.defaultSelectedId === undefined && props.selectedId === undefined ? unselect : props.defaultSelectedId,
    props.selectedId,
    props.onSelect
  );

  useSyncOnChange(selectedId, setFocusedId);

  const cancel = () => {
    setFocusedId(unselect);
    setSelectedId(unselect);
  };

  const select = (id: string | Unselect) => {
    setFocusedId(id);
    setSelectedId(id);
  };

  return {
    focusedId,
    setFocusedId,
    selectedId,
    cancel,
    select,
  } as const;
};
