import { createTheme, createVariant, globalTheme } from '@clds/component-theme';
import { PopoverProps } from './Popover';

export const popoverTheme = createTheme(
  {
    borderColor: globalTheme.palette.errorAlt,
    byMaxWidth: {
      sm: { maxWidth: '100px' },
      md: { maxWidth: '300px' },
      lg: { maxWidth: '400px' },
      xl: { maxWidth: '600px' },
      none: { maxWidth: 'none' },
    },
    byMinWidth: {
      sm: { minWidth: '100px' },
      md: { minWidth: '200px' },
      lg: { minWidth: '300px' },
      xl: { minWidth: '500px' },
      none: { minWidth: 'none' },
    },
    transition: {
      duration: '200ms',
    },
  },
  'popover'
);

type ThemeProps = Pick<PopoverProps, 'minWidth' | 'maxWidth'>;

export const popoverMinWidthVariant = createVariant((props: ThemeProps) => popoverTheme.byMinWidth[props.minWidth || 'none']);
export const popoverMaxWidthVariant = createVariant((props: ThemeProps) => popoverTheme.byMaxWidth[props.maxWidth || 'none']);
