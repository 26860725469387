import { useMemo } from 'react';
import { unselect, useListNavigation, useListNavigationHandlers, useQuerySelectorAll } from '@cld/compound-components';
import { MenuChildrenData, useChildRegistry } from '../childData';
import { ID_ATTRIBUTE_NAME } from '../MenuItem';
import { MenuProviderProps } from '../MenuProvider';
import { MenuState } from './useMenuState';

export const useMenuProviderProps = (registry: Map<unknown, MenuChildrenData>, state: MenuState): MenuProviderProps => ({
  registry,
  select: state.select,
  focusedId: state.focusedId,
  selectedId: state.selectedId,
});

 export const useConfirm = (registry: Map<unknown, MenuChildrenData>, state: MenuState) => {
  const getChildData = (id: unknown) => {
    const data = registry.get(id);
    if (!data) {
      throw new Error(`Cannot find data for item of id ${String(id)}. Please remember to use useRegisterChildData() hook in your list item`);
    }
    return data;
  };

  return () => {
    state.select(state.focusedId);
    if (state.focusedId !== unselect) {
      getChildData(state.focusedId).onClick?.();
    }
  };
};

export const useDescendants = () => {
  const { ref, result } = useQuerySelectorAll(`[${ID_ATTRIBUTE_NAME}]`);
  const ids = useMemo(() => result.map((element) => String(element.getAttribute(ID_ATTRIBUTE_NAME))), [result]);

  return { ref, ids };
};

export const useMenu = (state: MenuState) => {
  const registry = useChildRegistry();
  const { ref, ids } = useDescendants();
  const confirm = useConfirm(registry, state);
  const navigationHandlers = useListNavigationHandlers({ activeItem: state.focusedId, onActiveItemChange: state.setFocusedId, items: ids });
  const { focusableProps } = useListNavigation({
    ...navigationHandlers,
    onEscape: state.cancel,
    onEnter: confirm,
    onSpace: confirm,
  });
  const menuProviderProps = useMenuProviderProps(registry, state);

  return {
    descendantsParentRef: ref,
    focusableProps,
    menuProviderProps,
  };
};
