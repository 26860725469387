import { useEffect, useRef } from 'react';
import { useSnackbarContext } from '@cld/snackbar';
import { Environment, getEnvironment } from '@cld/console-apps-services';
import { DispatchAction } from '../actions';
import { State } from '../state';

// const parser = new WebVTTParser();

// FIXME: use zod
interface UploadResponseSchema {
  originalDurationSeconds: number;
  publicId: string;
}

interface UploadErrorResponse {
  message: string;
}

type UploadResponse = UploadErrorResponse | UploadResponseSchema;

// FIXME: extract injected client
const environment = getEnvironment();
export const PRODUCTION_CLIENT_URL = 'https://production-smart-ai-transformations-bff.cloudinary.com';
export const STAGING_CLIENT_URL = 'https://staging-smart-ai-transformations-bff.cloudinary.com';
export const apiClientUrl = environment === Environment.Production ? PRODUCTION_CLIENT_URL : STAGING_CLIENT_URL;

export const useVideoUpload = (dispatch: DispatchAction, clock: () => Date, videoUpload: State['videoUpload']) => {
  const requestInProgress = useRef(false);
  const addSnackbar = useSnackbarContext();

  useEffect(() => {
    if (videoUpload.status === 'pending' && !requestInProgress.current) {
      requestInProgress.current = true;

      const body = new FormData();
      body.append('video', videoUpload.file);

      void fetch(`${apiClientUrl}/video-summarization/upload`, { body, method: 'post' })
        .then((response) => response.json() as Promise<UploadResponse>)
        .then((data) => {
          if ('message' in data) {
            dispatch({ type: 'videoUploadFailed', payload: {} });
            addSnackbar({
              variant: 'error',
              message: `Upload failed${data.message ? ` - ${data.message}` : ''}`,
              timeout: 5000,
            });
          } else {
            dispatch({
              type: 'videoUploadSucceeded',
              payload: {
                originalLengthSeconds: data.originalDurationSeconds,
                publicId: data.publicId,
                timestamp: clock(),
              },
            });
          }
        })
        .catch(() => {
          addSnackbar({
            variant: 'error',
            message: 'Unknown error occurred while uploading',
            timeout: 5000,
          });
          dispatch({ type: 'videoUploadFailed', payload: {} });
        })
        .finally(() => {
          requestInProgress.current = false;
        });
    }
  }, [dispatch, clock, videoUpload, addSnackbar]);
};
