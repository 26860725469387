import React, { SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FileDownload } from '@clds/icon';
import { Checkbox } from '@clds/checkbox';
import { Button } from '@cld/button-next';
import { TermsOfServiceModal } from '../../mainPage/TermsOfServiceModal';
import { SectionTitle } from '../sectionTitle/SectionTitle';

const LS_TOS_SIGNED_KEY = 'ai-sum-tos-signed';

export interface DownloadProps {
  onVideoDownloadClick(): void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DownloadButton = styled(Button)`
  width: 120px;
`;

const TosCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;

const TosInlineButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const TosCheckboxText = styled.label`
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
`;

const TosBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const Download = (props: DownloadProps) => {
  const [tosSigned, setTosSigned] = useState(false);
  const [tosModalOpen, setTOSModalOpen] = useState(false);

  const openTOSModal = (e: SyntheticEvent) => {
    setTOSModalOpen(true);
    e.preventDefault();
  };

  useEffect(() => {
    try {
      const lsStatus = localStorage.getItem(LS_TOS_SIGNED_KEY);
      setTosSigned(lsStatus ? JSON.parse(lsStatus) === true : false);
    } catch (ex) {
      console.log('failed to read TOS signed status from local storage');
    }
  }, []);

  const onTosSigned = (newValue: boolean) => {
    setTosSigned(newValue);

    try {
      localStorage.setItem(LS_TOS_SIGNED_KEY, JSON.stringify(newValue));
    } catch (ex) {
      console.log('failed to save TOS signed status from local storage');
    }
  };

  return (
    <Container>
      <SectionTitle>Download result</SectionTitle>
      <TosBox>
        <TosCheckbox id="tosCheckBox" value={tosSigned} onChange={onTosSigned} />
        <TosCheckboxText htmlFor="tosCheckBox">
          I agree to the Cloudinary Labs <TosInlineButton onClick={openTOSModal}>Legal Disclaimer</TosInlineButton>
        </TosCheckboxText>
      </TosBox>

      <DownloadButton leftSlot={<FileDownload />} onClick={props.onVideoDownloadClick} size="md" isDisabled={!tosSigned}>
        Download
      </DownloadButton>
      <TermsOfServiceModal isOpen={tosModalOpen} setIsOpen={setTOSModalOpen} />
    </Container>
  );
};
