import { KeyboardHandlers, useKeyboardEventHandler } from './useKeyboardEventHandler';

export const useListNavigation = (handlers: KeyboardHandlers) => {
  const onKeyDown = useKeyboardEventHandler(handlers);

  return {
    focusableProps: {
      tabIndex: 0,
      onKeyDown,
    },
  } as const;
};
