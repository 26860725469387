import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';
import { Mute, Pause, Play, VolumeUp } from '@clds/icon';
import { LinkButton } from '@cld/button-next';
import { SectionedEventData } from '../state/effects/useAnalytics';
import { formatHumanReadableDuration } from './formatHumanReadableDuration';
import { useVideoInfo, Duration } from './useVideoInfo';
import { fixNaNDuration, getHighestDuration } from './durationHelpers';

interface VideoPreviewProps {
  originalLengthSeconds: number;
  videoNodeRef: React.RefObject<HTMLVideoElement>;
  videoUrl: string;
  desiredLengthValue: string;
  track: (eventData: SectionedEventData) => void;
}

const CurrentTime = styled.p`
  color: ${globalTheme.palette.contrastHigh};
  font-size: 14px;
  margin: 0;
`;

const InfoBar = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: flex;
  left: 0;
  padding: 6px ${globalTheme.spacing.sm};
  position: absolute;
  width: 100%;
`;

const OriginalLength = styled.p`
  color: ${globalTheme.palette.contrastHigh};
  font-size: 14px;
  margin: 0 auto 0 ${globalTheme.spacing.xl};
`;

export const ControlButton = styled(LinkButton)`
  line-height: 1;
  margin-right: ${globalTheme.spacing.xs};

  > svg {
    margin-bottom: -1px;
  }
`;

const Root = styled.div`
  position: relative;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  min-height: 350px;
  max-height: 55vh;
`;

export const VideoPreview = ({ originalLengthSeconds, videoNodeRef, videoUrl, desiredLengthValue, track }: VideoPreviewProps) => {
  const videoInfo = useVideoInfo(videoNodeRef);
  const originalLengthFormatted = useMemo(() => formatHumanReadableDuration(originalLengthSeconds), [originalLengthSeconds]);
  const currentTimeFormatted = useMemo(() => formatHumanReadableDuration(videoInfo.currentTime ?? 0), [videoInfo.currentTime]);
  const [durationFormatted, setDurationFormatted] = useState<string>('0:00');

  const handlePlayToggle = () => {
    if (videoNodeRef.current?.paused) {
      void videoNodeRef.current?.play();
      track({ event: 'short_video_play', eventDescription: 'play/pause' });
    } else {
      videoNodeRef.current?.pause();
      track({ event: 'short_video_pause', eventDescription: 'play/pause' });
    }
  };

  const handleSoundToggle = () => {
    if (videoNodeRef.current) {
      if (videoNodeRef.current.muted) {
        videoNodeRef.current.volume = 0.5;
        videoNodeRef.current.muted = false;
        track({ event: 'short_video_unmute', eventDescription: 'mute/unmute' });
      } else {
        videoNodeRef.current.muted = true;
        track({ event: 'short_video_mute', eventDescription: 'mute/unmute' });
      }
    }
  };

  useEffect(() => {
    const videoElement = videoNodeRef.current;
    const fixNaNAndSetDuration = (d: Duration) => setDurationFormatted(formatHumanReadableDuration(fixNaNDuration(d)));
    const handleVideoEnded = () => {
      if (videoInfo.duration) {
        fixNaNAndSetDuration(videoInfo.duration);
      }
    };

    // when playing a transformed video for the 1st time, it's duration value grows as the video's playing.
    // to solve this UX problem, we use the desired duration until the video finish loading.
    const highestDuration = getHighestDuration(videoInfo.duration, Number(desiredLengthValue));
    fixNaNAndSetDuration(highestDuration);

    // when the video ends, we update the duration with the actual value received from the player.
    videoElement?.addEventListener('ended', handleVideoEnded);

    return () => videoElement?.removeEventListener('ended', handleVideoEnded);
  }, [videoInfo, setDurationFormatted, desiredLengthValue, videoNodeRef]);

  return (
    <Root>
      <Video autoPlay muted preload="metadata" ref={videoNodeRef}>
        <source src={videoUrl} />
        <track kind="captions" />
      </Video>

      {videoInfo.duration > 0 && (
        <InfoBar>
          {videoInfo.paused ? (
            <ControlButton leftSlot={<Play />} onClick={handlePlayToggle} size="md" variant="overlay" />
          ) : (
            <ControlButton leftSlot={<Pause />} onClick={handlePlayToggle} size="md" variant="overlay" />
          )}

          {videoInfo.muted ? (
            <ControlButton leftSlot={<Mute />} onClick={handleSoundToggle} size="md" variant="overlay" />
          ) : (
            <ControlButton leftSlot={<VolumeUp />} onClick={handleSoundToggle} size="md" variant="overlay" />
          )}

          <CurrentTime>
            {currentTimeFormatted} / {durationFormatted}
          </CurrentTime>

          <OriginalLength>Original length {originalLengthFormatted}</OriginalLength>
        </InfoBar>
      )}
    </Root>
  );
};
