import React, { ComponentProps, FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import { TransitionActions } from 'react-transition-group/Transition';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { asStyledComponent } from '@cld/component-enhancer';
import { StyledTransitionOverlay } from './Slide.styled';

export interface SlideTransitionProps {
  timeout: CSSTransitionProps['timeout']
  transitionDirection: 'horizontal' | 'vertical';
  startPosition: string;
  endPosition: string;
}

export type SlideProps = SlideTransitionProps & ComponentProps<typeof CSSTransition> &
  TransitionActions & {
    className: string;
    cssTransitionProps?: ComponentProps<typeof CSSTransition>;
  };

export const Slide = asStyledComponent<FC<SlideProps>>(
  ({
    children,
    timeout = 250,
    mountOnEnter = true,
    unmountOnExit = true,
    appear = true,
    in: inProp,
    transitionDirection,
    startPosition,
    endPosition,
    cssTransitionProps,
    className,
  }) => {
    return (
      <CSSTransition
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
        appear={appear}
        in={inProp}
        timeout={timeout}
        classNames="transition-overlay"
        data-test="css-transition"
        {...cssTransitionProps}
      >
        <StyledTransitionOverlay
          timeout={timeout}
          transitionDirection={transitionDirection}
          startPosition={startPosition}
          endPosition={endPosition}
          className={className}
        >
          {children}
        </StyledTransitionOverlay>
      </CSSTransition>
    );
  }
);
