import { RefObject, useCallback, useEffect } from 'react';
import { interceptFocus, onOutsideModalClick } from './ModalOverlay.utils';
import { ModalOverlayProps } from './ModalOverlay';

export const useModalCloseEvents = (
  onClose: ModalOverlayProps['onClose'],
  closeOnEsc: ModalOverlayProps['closeOnEsc'],
  closeOnOutsideClick: ModalOverlayProps['closeOnOutsideClick'],
  modalRef: RefObject<HTMLDivElement>
) => {
  const onKeyDownCallback = useCallback(
    (event: KeyboardEvent) => {
      const isEscPressed = event.key === 'Escape' || event.keyCode === 27;
      const isTabPressed = event.key === 'Tab' || event.keyCode === 9;

      if (isEscPressed && closeOnEsc) {
        onClose();
        return;
      }

      if (isTabPressed) {
        interceptFocus(event, modalRef);
      }
    },
    [closeOnEsc, modalRef, onClose]
  );

  const onOutsideClickCallback = useCallback(
    (event: MouseEvent) => {
      onOutsideModalClick(event, modalRef, onClose, closeOnOutsideClick);
    },
    [closeOnOutsideClick, modalRef, onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDownCallback);
    document.addEventListener('click', onOutsideClickCallback);

    return () => {
      document.removeEventListener('keydown', onKeyDownCallback);
      document.removeEventListener('click', onOutsideClickCallback);
    };
  }, [onKeyDownCallback, onOutsideClickCallback]);
};
