import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { CloudinaryLogo } from '@clds/icon';
import { SectionedEventData } from '../state/effects/useAnalytics';

export const LEAVE_US_MESSAGE_HREF = 'https://docs.google.com/forms/d/e/1FAIpQLSfqVdlZNveD_KQf1CjQ-pd_d1-YiecF1tTNAbRBe4wRkgx97w/viewform?usp=sf_link';

const Brand = styled.span`
  color: ${globalTheme.palette.contrastHigh};
  font-size: 20px;
  font-weight: 500;
`;

const Labs = styled.span`
  color: ${globalTheme.palette.contrastHigh};
  font-size: 32px;
  font-weight: 200;
  letter-spacing: 2.5px;
  margin-left: 10px;
`;

const Logo = styled(CloudinaryLogo)`
  color: ${globalTheme.palette.contrastHigh};
  margin-right: ${globalTheme.spacing.xxs};
`;

const Root = styled.header`
  align-items: center;
  border-bottom: 5px solid ${globalTheme.palette.surface};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.29);
  display: flex;
  padding: 15px 78px 15px 32px;
  z-index: 1;
`;
const RootMobile = styled(Root)`
  background-color: ${globalTheme.palette.background};
  padding: 15px;
  justify-content: center;
`;

export const ContactUsText = styled.a`
  color: ${globalTheme.palette.contrastHigh};
  font-size: 18px;
  font-weight: 700;
  margin-left: auto;
`;

const Common = () => (
  <>
    <Logo size="xl" />
    <Brand>Cloudinary</Brand>
    <Labs>LABS</Labs>
  </>
);

export const BrandBar = ({ track }: { track: (eventData: SectionedEventData) => void }) => (
  <Root>
    <Common />
    <ContactUsText
      href={LEAVE_US_MESSAGE_HREF}
      target="_blank"
      onClick={() => track({ event: 'contact_navigate', eventDescription: 'click talk to us/leave a message' })}
    >
      Leave us a message
    </ContactUsText>
  </Root>
);

export const BrandBarMobile = () => (
  <RootMobile>
    <Common />
  </RootMobile>
);
