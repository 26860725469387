import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { mix } from 'polished';
import { combineValues } from '@clds/component-theme';
import { BaseButton } from './BaseButton';
import { propTypes, defaultProps, VARIANT } from './props';
import { buttonTheme } from './buttonTheme';

const focusCss = ({ shape, buttonType }) => css`
  :focus:not(:active) {
    background-color: ${buttonTheme[shape]?.[buttonType]?.background?.color};
  }
`;

const activeCss = ({ shape, buttonType }) => css`
  :active {
    background-color: ${combineValues(
      buttonTheme[shape]?.[buttonType]?.background?.color,
      buttonTheme[shape]?.[buttonType]?.background?.contrast,
      (color, overlayColor) => mix(0.12, overlayColor, color)
    )};
  }
`;

const hoverCss = ({ shape, buttonType }) => css`
  :hover {
    background-color: ${combineValues(
      buttonTheme[shape]?.[buttonType]?.background?.color,
      buttonTheme[shape]?.[buttonType]?.background?.contrast,
      (color, overlayColor) => mix(0.06, overlayColor, color)
    )};
  }
`;

const StyledButton = styled(BaseButton).attrs(({ variant, nativeType }) => ({ buttonType: variant, type: nativeType, 'data-test-type': variant }))`
  ${({ shape, buttonType }) => css`
    background-color: ${buttonTheme[shape]?.[buttonType]?.background?.color};
  `};

  ${(props) =>
    !props.selected &&
    css`
      ${focusCss} ${hoverCss} ${activeCss}
    `};
`;

StyledButton.propTypes = {
  ...propTypes,
  variant: PropTypes.oneOf(Object.values(VARIANT)),
};

StyledButton.defaultProps = {
  ...defaultProps,
  variant: VARIANT.PRIMARY,
  progress: false,
  nativeType: 'button',
};

export { StyledButton as Button };
