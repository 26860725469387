import React, { useLayoutEffect, useState } from 'react';

export const useSize = (elementRef: React.RefObject<HTMLElement>) => {
  const [size, setSize] = useState<{ height?: number; width?: number }>({ height: undefined, width: undefined });

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      const newSizes = elementRef.current?.getBoundingClientRect();

      if (newSizes) {
        setSize({ height: newSizes.height, width: newSizes.width });
      }
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  }, [elementRef]);

  return size;
};
