// import { v4 as uuidV4 } from 'uuid';
import { ComponentProps, ComponentType } from 'react';
import styled, { isStyledComponent, StyledComponent } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const asStyledComponent = <Type extends ComponentType<ComponentProps<Type>>>(Component: Type): StyledComponent<Type, any> => {
  if (isStyledComponent(Component)) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    console.warn('asStyledComponent', `Component ${Component.displayName} is already a styled-component`);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Component as StyledComponent<Type, any>;
  }

  const WrappedComponent = styled(Component)``;
  //If we want to enable SSR in styled-component babel plugin this can be considered
  // WrappedComponent.styledComponentId += `-${uuidv4().substr(0, 6)}`;

  return WrappedComponent;
};

export default asStyledComponent;
