import { createContext, useCallback } from 'react';
import { createUseDefinedContext } from '../../utils/hooks/createUseDefinedContext';
import { createPropsContextProvider } from '../../utils/createPropsContextProvider';
import { Unselect } from '../..';

export interface ListSelection {
  /** Id of currently selected item */
  selectedId?: string | Unselect; // TODO consider removing selection either selection or focus
  //                      TODO from this context and make this mechanism reusable twice - we need to decouple this
  /** Id of item that is currently highlighted using navigation keys */
  focusedId?: string | Unselect;
  onClick: (id: string) => void;
}

const context = createContext<ListSelection | undefined>(undefined);

export const ListSelectionProvider = createPropsContextProvider(context);

export const useListSelectionContext = createUseDefinedContext(
  context,
  'useListSelectionContext requires ListSelectionContext to read list item properties'
);

export const useListSelection = (id: string) => {
  const { onClick, selectedId, focusedId } = useListSelectionContext();

  const isSelected = selectedId === id;
  const isFocused = focusedId === id;
  const onClickInjected = useCallback(() => onClick(id), [id, onClick]);

  return { isSelected, isFocused, onClick: onClickInjected };
};
