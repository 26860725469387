import styled, { css } from 'styled-components';
import { modalLayoutTheme } from '../theme';

export interface ModalFooterProps {
  /** Enables alternative colors */
  accent?: boolean;
}

export const ModalFooter = styled.div<ModalFooterProps>`
  padding: ${modalLayoutTheme.layout.spacing.vertical} ${modalLayoutTheme.layout.spacing.horizontal};
  background-color: ${modalLayoutTheme.layout.header.background.color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: -webkit-fit-content;

  ${({ accent }) =>
    accent &&
    css`
      background-color: ${modalLayoutTheme.layout.footer.accent.background.color};
      border-top: ${modalLayoutTheme.layout.footer.accent.borderTop.width} solid ${modalLayoutTheme.layout.footer.accent.borderTop.color};
    `}
`;
