import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';

import arrow from '../../assets/images/arrow.svg';
import circle from '../../assets/images/circle.svg';

const dots = 36;
const dotSize = 4;
const dotSpacing = 6;
const dotsPerRow = 6;
const dotArraySize = dotSize * dotsPerRow + dotSpacing * (dotsPerRow - 1);

const Arrow = styled.img`
  position: absolute;
  right: -200px;
  top: 78px;
  z-index: 1;
`;

const CircleLarge = styled.img`
  position: absolute;
  left: 22px;
  top: 298px;
  width: 87px;
  height: 87px;
`;

const CircleSmall = styled(CircleLarge)`
  left: -19px;
  top: 176px;
  width: 45px;
  height: 45px;
`;

const Container = styled.div`
  position: relative;
`;

const Bar = styled.div`
  width: 216px;
  height: 5px;
  background-color: ${globalTheme.palette.contrastHigh};
  position: absolute;
  top: -10px;
  left: 55%;
  z-index: 2;
`;

const EmptyBar = styled(Bar)`
  opacity: 0.6;
`;

const HalfBar = styled(Bar)`
  width: 42px;
  z-index: 3;
`;

const Dot = styled.div<{ columnIndex: number; rowIndex: number }>`
  background-color: ${globalTheme.palette.dataVis.accent2};
  border-radius: 50%;
  height: ${dotSize}px;
  left: ${({ columnIndex }) => -(dotArraySize / 2) + columnIndex * (dotSize + dotSpacing)}px;
  position: absolute;
  top: ${({ rowIndex }) => -(dotArraySize / 2) + rowIndex * (dotSize + dotSpacing)}px;
  width: ${dotSize}px;
`;

const DotsAnchor = styled.div`
  position: absolute;
  left: 307px;
  top: 328px;
`;

const OriginalVideoContainer = styled.div`
  display: inline-block;
  position: absolute;
  z-index: 1;
`;

const SummaryVideoContainer = styled(OriginalVideoContainer)`
  left: 300px;
  top: 140px;
`;

const StyledVideo = styled.video`
  width: 370px;
  height: 200px;
`;

const Text = styled.div`
  color: ${globalTheme.palette.contrastHigh};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  position: absolute;
  top: -45px;
  left: 55%;
`;

export const MarketingImage = () => (
  <Container>
    <OriginalVideoContainer>
      <Text>Duration 2:39</Text>
      <Bar />
      <StyledVideo src={ORIGINAL_VIDEO} muted controls poster={VIDEO_POSTER} />
    </OriginalVideoContainer>
    <SummaryVideoContainer>
      <Text>Duration 0:19</Text>
      <EmptyBar />
      <HalfBar />
      <StyledVideo src={SUMMARY_VIDEO} autoPlay muted controls loop poster={VIDEO_POSTER} />
    </SummaryVideoContainer>

    <Arrow src={arrow} />
    <CircleSmall src={circle} />
    <CircleLarge src={circle} />

    <DotsAnchor>
      {Array(dots)
        .fill(null)
        .map((_, index) => (
          <Dot columnIndex={index % dotsPerRow} key={index} rowIndex={Math.floor(index / dotsPerRow)} />
        ))}
    </DotsAnchor>
  </Container>
);

const ORIGINAL_VIDEO = 'https://demo-res.cloudinary.com/video/upload/w_370/f_auto/q_auto/AirbnbsListing.mp4';
const VIDEO_POSTER = 'https://demo-res.cloudinary.com/video/upload/w_370/f_auto/q_auto/AirbnbsListing.jpg';
const SUMMARY_VIDEO = 'https://demo-res.cloudinary.com/video/upload/e_preview:duration_19:max_seg_9:min_seg_dur_1/w_370/f_auto/q_auto/AirbnbsListing.mp4';
