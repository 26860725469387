import { normalize } from 'polished';
import React, { useMemo } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { globalTheme } from '@clds/component-theme';
import { generateTheme } from '@clds/theme-helper';
import { dark } from '@clds/themes';
import { SnackbarProvider } from '@cld/snackbar';
import { RootPage } from './RootPage';
import { sendAnalytics } from './sendAnalytics';

const PageStyle = createGlobalStyle`
  ${normalize()};

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a,
  a:visited {
    text-decoration: none;
  }

  body {
    background-color: ${globalTheme.palette.background};
    color: ${globalTheme.color.gray70};
    font-family: sofia-pro, sans-serif;
    font-size: ${globalTheme.globals.font.size};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: normal;
    letter-spacing: normal;
  }
`;

const clock = () => new Date();

export const SmartAiTransformations = () => {
  const theme = useMemo(() => generateTheme(dark), []);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <PageStyle />

        <RootPage analytics={sendAnalytics} clock={clock} />
      </SnackbarProvider>
    </ThemeProvider>
  );
};
