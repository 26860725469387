import styled from 'styled-components';
import { createGlobalStyle } from '@clds/theme-helper';
import { globalTheme } from '@clds/component-theme';
import { Paper } from '@clds/paper';
import { popoverMaxWidthVariant, popoverMinWidthVariant, popoverTheme } from './theme';
import { PopoverProps } from './Popover';

// source: https://github.com/react-component/tooltip/blob/master/assets/bootstrap_white.less

const spacing = globalTheme.spacing.xxs;

export const Global = createGlobalStyle`
  .cld-popover.cld-popover-zoom-enter,
  .cld-popover.cld-popover-zoom-leave {
    display: block;
  }
  .cld-popover-zoom-enter,
  .cld-popover-zoom-appear {
    opacity: 0;
    animation-duration: ${popoverTheme.transition.duration};
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-play-state: paused;
  }
  .cld-popover-zoom-leave {
    animation-duration: ${popoverTheme.transition.duration};
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-play-state: paused;
  }
  .cld-popover-zoom-enter.cld-popover-zoom-enter-active,
  .cld-popover-zoom-appear.cld-popover-zoom-appear-active {
    animation-name: cldPopoverStart;
    animation-play-state: running;
  }
  .cld-popover-zoom-leave.cld-popover-zoom-leave-active {
    animation-name: cldPopoverEnd;
    animation-play-state: running;
  }
  @keyframes cldPopoverStart {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes cldPopoverEnd {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .cld-popover {
    position: absolute;
    z-index: 10000;
    display: block;
    visibility: visible;
    opacity: 1;
  }
  .cld-popover-hidden {
    display: none;
  }
  .cld-popover-placement-top,
  .cld-popover-placement-topLeft,
  .cld-popover-placement-topRight {
    padding: 5px 0 ${spacing} 0;
  }
  .cld-popover-placement-right,
  .cld-popover-placement-rightTop,
  .cld-popover-placement-rightBottom {
    padding: 0 5px 0 ${spacing};
  }
  .cld-popover-placement-bottom,
  .cld-popover-placement-bottomLeft,
  .cld-popover-placement-bottomRight {
    padding: ${spacing} 0 5px 0;
  }
  .cld-popover-placement-left,
  .cld-popover-placement-leftTop,
  .cld-popover-placement-leftBottom {
    padding: 0 ${spacing} 0 5px;
  }
  .cld-popover-inner {
    min-height: 34px;
  }
  .cld-popover-arrow {
    display: none;
  }
`;

type ThemeProps = Pick<PopoverProps, 'minWidth' | 'maxWidth'>;

export const StyledPaper = styled(Paper) <ThemeProps>`
  min-width: ${popoverMinWidthVariant((variant) => variant.minWidth)};
  max-width: ${popoverMaxWidthVariant((variant) => variant.maxWidth)};
`;
