import { useEffect, useCallback } from 'react';

import { DispatchAction } from '../actions';
import { State } from '../state';

const rotationIntervalMilliseconds = 10000;

export const usePollingTextRotation = (dispatch: DispatchAction, videoPolling: State['videoPolling']) => {
  const rotatePollingText = useCallback(() => {
    dispatch({ type: 'pollingTextSeedIncremented', payload: {} });
  }, [dispatch]);

  useEffect(() => {
    let intervalId: number | undefined;

    if (videoPolling.status === 'running') {
      intervalId = window.setInterval(rotatePollingText, rotationIntervalMilliseconds);
    }

    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [videoPolling.status, rotatePollingText]);
};
