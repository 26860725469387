import { useCallback, useContext, useState } from 'react';
import { ModalQueueContext } from './ModalProvider/context';

export type ModalStateValues = [isModalOpen: boolean, setModalOpen: (openState: boolean) => void];

export const useModal = (): ModalStateValues => {
  const [modalId] = useState(Symbol());
  const { queue, setOpen } = useContext(ModalQueueContext);
  const setModalOpen = useCallback(
    (openState: boolean) => {
      setOpen(modalId, openState);
    },
    [modalId, setOpen]
  );

  const isModalOpen = queue[0] === modalId;

  return [isModalOpen, setModalOpen];
};
