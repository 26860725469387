import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { TouchScrollable } from 'react-scrolllock';
import { StylableComponentProps } from '@clds/common-definitions';
import { globalTheme } from '@clds/component-theme';
import { typography } from '@clds/typography';
import { media } from '@clds/style-utils';
import { modalLayoutTheme } from '../theme';

export interface ContentProps extends StylableComponentProps {
  accent?: boolean;
}

const Content = styled.div<ContentProps>`
  max-height: calc(100vh - 2 * (${modalLayoutTheme.layout.box.margin} + ${globalTheme.spacing.xxl} + ${globalTheme.spacing.xl}));
  padding: 0 ${modalLayoutTheme.layout.spacing.horizontal};
  background-color: ${modalLayoutTheme.layout.content.background.color};
  overflow-y: auto;

  ${typography.xs.lowContrast};

  @media ${media.desktop({ minOnly: true })} {
    max-height: calc(100vh - 6 * ${modalLayoutTheme.layout.box.margin} - 2 * (${globalTheme.spacing.xxl} + ${globalTheme.spacing.xl}));
  }

  @media ${media.phone()} {
    max-height: calc(100vh - 2 * (${globalTheme.spacing.xxl} + ${globalTheme.spacing.xl}));
  }

  ${({ accent }) =>
    accent &&
    css`
      padding: ${modalLayoutTheme.layout.spacing.vertical} ${modalLayoutTheme.layout.spacing.horizontal} ${modalLayoutTheme.layout.content.spacing.bottom};
    `}
`;

export const ModalContent: FC<ContentProps> = ({ children, accent, className }) => (
  <TouchScrollable>
    <Content accent={accent} className={className}>
      {children}
    </Content>
  </TouchScrollable>
);
