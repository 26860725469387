import React, { FC, useMemo } from 'react';
import { StylableComponentProps } from '@clds/common-definitions';
import { Item, ItemProps } from '@clds/item';
import { asStyledComponent } from '@cld/component-enhancer';
import { mergeCallbacks, useListSelection, useListStyle, useUniqueId } from '@cld/compound-components';
import { useRegisterChildData } from './childData';

export const ID_ATTRIBUTE_NAME = 'data-selectable-id';

export interface MenuItemProps extends ItemProps, StylableComponentProps {
  id?: string;
}

export const useMenuItemProps = ({ onClick, id: idProvided, ...rest }: MenuItemProps) => {
  const listStyle = useListStyle();
  const id = useUniqueId(idProvided);

  const childData = useMemo(() => ({ onClick }), [onClick]);
  useRegisterChildData(id, childData);

  const { onClick: onClickListSelection, ...selection } = useListSelection(id);

  const onClickCombined = mergeCallbacks(onClickListSelection, onClick);
  // TODO currently we don't want to show menu items focused and selected at the same time
  // this is because after clicking the menu it receives both focused and selected and you expect just the normal state
  // we should find the way to indicate focused&selected only after the keyboard interaction
  const shouldFocusedBeIndicated = selection.isFocused && selection.isSelected ? false : selection.isFocused;
  return {
    [ID_ATTRIBUTE_NAME]: id,
    ...listStyle,
    ...selection,
    isFocused: shouldFocusedBeIndicated,
    onClick: onClickCombined,
    ...rest,
  };
};

export const MenuItem = asStyledComponent<FC<Partial<MenuItemProps>>>(({ children, ...rest }) => {
  const listItemProps = useMenuItemProps(rest);

  return <Item {...listItemProps}>{children}</Item>;
});
