import styled, { css } from 'styled-components';
import { TypographySize } from '@clds/typography';
import { blenderCss } from '@clds/blender';
import { focusRing, focusVisible } from '@clds/style-utils';
import { supportFieldLineLayout } from '@clds/toolbar-layout';
import { focusBorderColorBlenderMapping } from './blenderMapping';
import { buttonNextSizeVariant, shapeVariant } from './buttonTheme';
import { BaseBlenderProps, ButtonThemeProps } from './types';

export const buttonStyle = css<BaseBlenderProps & ButtonThemeProps>`
  background-color: ${blenderCss.backgroundFromCssVariables};
  border-radius: ${shapeVariant((variant) => variant.radius)};
  padding: 0 ${buttonNextSizeVariant((variant) => variant.paddingH)};
  height: ${buttonNextSizeVariant((variant) => variant.height)};
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  ${supportFieldLineLayout()};
  ${focusRing('inset 0 0 0 0 transparent', 'background 250ms, border-color 150ms')};

  border: 1px solid transparent;
  &${focusVisible} {
    border-color: ${focusBorderColorBlenderMapping};
  }
  &[aria-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const LeftSlotWrapper = styled.div`
  line-height: 0; // make sure icon is centered inside wrapper
  margin: ${buttonNextSizeVariant((variant) => variant.paddingH)} 0;
  &:not(:last-child) {
    margin-right: ${buttonNextSizeVariant((variant) => variant.paddingH)};
  }
`;

export const RightSlotWrapper = styled.div`
  line-height: 0; // make sure icon is centered inside wrapper
  margin: ${buttonNextSizeVariant((variant) => variant.paddingH)} 0;
  &:not(:first-child) {
    margin-left: ${buttonNextSizeVariant((variant) => variant.paddingH)};
  }
`;

export const TextWrapper = styled.div`
  ${buttonNextSizeVariant((variant) => blenderCss.typographyFromCssVariables(variant.typographySize as TypographySize))};
`;
