import styled, { css } from 'styled-components';
import { ellipsis } from 'polished';
import { typography } from '@clds/typography';
import { modalLayoutTheme } from '../theme';

export interface ModalHeaderProps {
  /** Enables alternative colors */
  accent?: boolean;
}

export const ModalHeader = styled.div<ModalHeaderProps>`
  padding: ${modalLayoutTheme.layout.spacing.vertical} ${modalLayoutTheme.layout.spacing.horizontal};
  background-color: ${modalLayoutTheme.layout.header.background.color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: -webkit-fit-content;
  ${typography.lg.regular};

  ${({ accent }) =>
    accent &&
    css`
      background-color: ${modalLayoutTheme.layout.header.accent.background.color};
      border-bottom: ${modalLayoutTheme.layout.header.accent.borderBottom.width} solid ${modalLayoutTheme.layout.header.accent.borderBottom.color};
    `}
`;

export const ModalTitle = styled.div`
  ${ellipsis()};
`;
