import { createTheme, createVariant } from '@clds/component-theme';

export const iconTheme = createTheme(
  {
    xs: '0.5rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '2rem',
    xxl: '2.625rem',
  },
  'icon'
);

export const iconSizeVariant = createVariant((props) => iconTheme[props.size]);
