import { createTheme, createVariant } from '@clds/component-theme';
import { TypographySize, TypographyType } from '@clds/typography';
import { Size } from './Avatar.types';

const asTypography = (size: TypographySize, type: TypographyType) => ({ type, size });

export const avatarTheme = createTheme({
  sm: {
    width: '24px',
    typography: asTypography('xs', 'regular'),
    iconSize: 'sm',
  },
  md: {
    width: '28px',
    typography: asTypography('xs', 'regular'),
    iconSize: 'sm',
  },
  lg: {
    width: '32px',
    typography: asTypography('sm', 'regular'),
    iconSize: 'md',
  },
});

export const avatarSizeVariant = createVariant((props: { size: Size }) => avatarTheme[props.size]);
