import { globalTheme } from '@clds/component-theme';
import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  color: ${globalTheme.palette.contrastLow};
  font-size: 12px;
  line-height: 18px;
`;

const Link = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: ${globalTheme.palette.contrastLow};

  &:visited {
    color: ${globalTheme.palette.contrastLow};
  }
`;

export const LegalText = ({ onClick }: { onClick: () => void }) => (
  <Text>
    Uploading a video or URL is subject to the following{' '}
    <strong>
      <Link href="#" onClick={onClick}>
        Cloudinary Labs Legal Disclaimer
      </Link>{' '}
      and{' '}
      <Link href="https://cloudinary.com/privacy" target="_blank" rel="noreferrer noopener">
        Privacy Policy.
      </Link>
    </strong>
  </Text>
);
