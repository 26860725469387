import React, { FC, ReactElement } from 'react';
import { ModalNext } from '@cld/modal-next';
import { ModalCard, ModalCloseIcon, ModalContent, ModalFooter, ModalHeader } from '@cld/modal-layout';

interface ModalProps {
  isOpen?: boolean;
  onClose: () => void;
  onCloseButton?: ReactElement;
  onAcceptButton?: ReactElement;
  headerText?: string;
}

export const Modal: FC<ModalProps> = ({ isOpen = false, onClose, onCloseButton = null, onAcceptButton = null, headerText, children }) => {
  return (
    <ModalNext isOpen={isOpen} onClose={onClose} closeOnEsc closeOnOutsideClick>
      <ModalCard>
        <ModalHeader>
          {headerText}
          <ModalCloseIcon />
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
        <ModalFooter>
          {onCloseButton}
          {onAcceptButton}
        </ModalFooter>
      </ModalCard>
    </ModalNext>
  );
};
