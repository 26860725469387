import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { TitleMobile } from '../components/marketingTitle/Title';
import { BrandBarMobile, LEAVE_US_MESSAGE_HREF } from '../mainPage/BrandBar';
import circle from '../assets/images/circle.svg';
import { SectionedEventData } from '../state/effects/useAnalytics';

const Wrapper = styled.div`
  background-color: ${globalTheme.palette.primary};
  color: ${globalTheme.palette.surface};
  text-align: center;
  min-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 15px 15px;
  background-color: ${globalTheme.palette.primary};
  color: ${globalTheme.palette.surface};
`;

const Text = styled.p`
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
`;

const StyledLink = styled.a`
  text-align: center;
  padding: 16px 24px;
  border-radius: 29px;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: ${globalTheme.palette.primary};
  background-color: ${globalTheme.palette.background};
`;

const ContactUsText = styled.a`
  color: ${globalTheme.palette.surface};
  font-size: 18px;
  font-weight: 700;
  margin: 55px auto;
`;

const MarketingImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Circle = styled.img`
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  bottom: -46px;
  left: -37px;
`;

export const MobilePage = ({ track }: { track: (eventData: SectionedEventData) => void }) => (
  <Wrapper>
    <BrandBarMobile />
    <Container>
      <TitleMobile />
      <MarketingImage
        src="https://res.cloudinary.com/prod/image/upload/q_auto,f_auto/smart-ai-transformations/marketing-mobile"
        alt="Cloudinary AI video summary"
      />
      <Text>Sorry, this solution is available on desktop only. In the meantime, feel free to go to</Text>
      <StyledLink href="https://www.cloudinary.com" rel="noreferrer noopener">
        Cloudinary's home page
      </StyledLink>
      <ContactUsText
        href={LEAVE_US_MESSAGE_HREF}
        target="_blank"
        onClick={() => track({ event: 'contact_navigate', eventDescription: 'click talk to us/leave a message' })}
      >
        Leave us a message
      </ContactUsText>
    </Container>
    <Circle src={circle} />
  </Wrapper>
);
