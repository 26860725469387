import { differenceInMilliseconds } from 'date-fns/fp';

import { Action } from './actions';
import { presets } from './presets';
import { State, initialState } from './state';
import { validateLength } from './validateLength';

export const reducer = (state: State, action: Action): State => {
  if (action.type === 'downloadQueued') {
    if (state.videoPreview.status !== 'success') {
      throw new Error('Corrupted state');
    }

    return { ...state, videoDownload: { status: 'pending', videoUrl: state.videoPreview.downloadUrl } };
  }

  if (action.type === 'downloadStarted') {
    return { ...state, videoDownload: { status: 'idle' } };
  }

  if (action.type === 'fileSelected') {
    return {
      ...state,
      selectedSource: { type: 'file', file: action.payload.newFile },
      videoUpload: { status: 'pending', file: action.payload.newFile },
    };
  }

  if (action.type === 'lengthChanged') {
    return { ...state, lengthErrorMessage: validateLength(action.payload.newValue, state.videoOriginalDuration), lengthValue: action.payload.newValue };
  }

  if (action.type === 'pollingTextSeedIncremented') {
    return { ...state, pollingTextSeed: state.pollingTextSeed + 1 };
  }

  if (action.type === 'presetSelected') {
    return { ...state, selectedSource: { type: 'preset', preset: action.payload.newPreset } };
  }

  if (action.type === 'videoOriginalDuration') {
    return { ...state, videoOriginalDuration: action.payload.duration, lengthErrorMessage: validateLength(state.lengthValue, action.payload.duration) };
  }

  if (action.type === 'setupSubmitted') {
    if (!state.selectedSource) {
      throw new Error('Corrupted state');
    }

    if (state.lengthErrorMessage !== undefined) {
      throw new Error('Attempted submitting form with errors');
    }

    let publicId: string;

    if (state.selectedSource.type === 'file') {
      if (state.videoUpload.status !== 'success') {
        throw new Error('Corrupted state');
      }

      publicId = state.videoUpload.publicId;
    } else {
      publicId = presets[state.selectedSource.preset].publicId;
    }

    return { ...state, videoPreview: { status: 'pending', lengthSeconds: parseInt(state.lengthValue, 10), publicId } };
  }

  if (action.type === 'videoInfoFetchFailed') {
    return { ...state, videoInfoFetch: { status: 'error', errorMessage: action.payload.errorMessage } };
  }

  if (action.type === 'videoInfoFetchSucceeded') {
    return {
      ...state,
      videoInfoFetch: { status: 'success', cutSegments: action.payload.cutSegments, relevanceHistogram: action.payload.relevanceHistogram },
    };
  }

  if (action.type === 'videoPreviewFailed') {
    return { ...state, videoPreview: { status: 'error', errorMessage: action.payload.errorMessage } };
  }

  if (action.type === 'videoPreviewSucceeded') {
    if (!state.selectedSource) {
      throw new Error('Corrupted state');
    }

    let originalLengthSeconds: number;

    if (state.selectedSource.type === 'file') {
      if (state.videoUpload.status !== 'success') {
        throw new Error('Corrupted state');
      }

      originalLengthSeconds = state.videoUpload.originalLengthSeconds;
    } else {
      originalLengthSeconds = presets[state.selectedSource.preset].originalLengthSeconds;
    }

    return {
      ...state,
      page: 'results',
      videoPolling: {
        status: 'running',
        lastPollTimestamp: action.payload.timestamp,
        startTimestamp: action.payload.timestamp,
        videoUrl: action.payload.videoUrl,
      },
      videoPreview: {
        originalLengthSeconds,
        status: 'success',
        spriteSheetData: action.payload.spriteSheetData,
        spriteSheetUrl: action.payload.spriteSheetUrl,
        videoInfoUrl: action.payload.videoInfoUrl,
        videoUrl: action.payload.videoUrl,
        downloadUrl: action.payload.downloadUrl,
      },
    };
  }

  if (action.type === 'videoPollingFinished') {
    if (state.videoPolling.status !== 'running' || state.videoPreview.status !== 'success') {
      throw new Error('Corrupted state');
    }

    const pollingTimeMilliseconds = differenceInMilliseconds(state.videoPolling.startTimestamp, action.payload.timestamp);

    return {
      ...state,
      videoInfoFetch: { status: 'pending', videoInfoUrl: state.videoPreview.videoInfoUrl },
      videoPolling: { status: 'finished', pollingTimeMilliseconds },
    };
  }

  if (action.type === 'videoPollingTicked') {
    if (state.videoPolling.status !== 'running') {
      throw new Error('Corrupted state');
    }

    return {
      ...state,
      videoPolling: {
        ...state.videoPolling,
        lastPollTimestamp: action.payload.timestamp,
      },
    };
  }

  if (action.type === 'videoUploadFailed') {
    return { ...state, videoUpload: { status: 'error' } };
  }

  if (action.type === 'videoUploadSucceeded') {
    return {
      ...state,
      videoUpload: {
        status: 'success',
        originalLengthSeconds: action.payload.originalLengthSeconds,
        publicId: action.payload.publicId,
      },
    };
  }

  // we have a series of actions named "choosingRating#": choosingRating1, choosingRating2, etc.
  if (action.type.startsWith('chooseRating')) {
    return { ...state, rating: action.payload.rating };
  }

  if (action.type === 'resetState') {
    return { ...initialState };
  }

  throw new Error('State management: unknown action type');
};
