import React, { useState } from 'react';

import { PresetName } from '../state/presets';
import { State } from '../state/state';
import { SectionedEventData } from '../state/effects/useAnalytics';
import { Title } from '../components/marketingTitle/Title';
import { SourceSelection } from './rightPane/SourceSelection';
import { LengthSelection } from './rightPane/LengthSelection';
import { SubmitButton } from './rightPane/SubmitButton';
import { PanesLayout } from './PanesLayout';
import { MarketingImage } from './leftPane/MarketingImage';
import { TransformationName } from './rightPane/TransformationName';
import { BrandBar } from './BrandBar';
import { LegalText } from './rightPane/LegalText';
import { TermsOfServiceModal } from './TermsOfServiceModal';

interface SetupPageProps {
  isSubmitInProgress: boolean;
  lengthErrorMessage?: string;
  selectedFile?: File;
  selectedPreset?: PresetName;
  lengthValue: string;
  videoUpload: State['videoUpload'];
  onFileSelect: (file: File) => void;
  onLengthChange: (newValue: string) => void;
  onPresetSelect: (preset: PresetName) => void;
  onSubmit: () => void;
  onOriginalDurationChange: (duration: number) => void;
  track: (eventData: SectionedEventData) => void;
}

export const MainPage = ({
  isSubmitInProgress,
  lengthErrorMessage,
  lengthValue,
  onFileSelect,
  onLengthChange,
  onPresetSelect,
  onSubmit,
  onOriginalDurationChange,
  selectedFile,
  selectedPreset,
  videoUpload,
  track,
}: SetupPageProps) => {
  const [tosModalOpen, setTOSModalOpen] = useState(false);

  const openTOSModal = () => {
    setTOSModalOpen(true);
  };

  return (
    <>
      <BrandBar track={track} />

      <PanesLayout.Container>
        <PanesLayout.LeftPane>
          <div>
            <Title />
            <MarketingImage />
          </div>
        </PanesLayout.LeftPane>

        <PanesLayout.RightPane>
          <TransformationName />
          <SourceSelection
            onFileSelect={onFileSelect}
            onSelectPreset={onPresetSelect}
            onOriginalDurationChange={onOriginalDurationChange}
            selectedFile={selectedFile}
            selectedPreset={selectedPreset}
            videoUpload={videoUpload}
            track={track}
          />
          <LengthSelection errorMessage={lengthErrorMessage} onChange={onLengthChange} value={lengthValue} />
          <SubmitButton
            isDisabled={(selectedFile && videoUpload.status !== 'success') || (!selectedFile && !selectedPreset) || !!lengthErrorMessage}
            isLoading={isSubmitInProgress}
            onClick={onSubmit}
          />
          <LegalText onClick={openTOSModal} />
        </PanesLayout.RightPane>
      </PanesLayout.Container>

      <TermsOfServiceModal isOpen={tosModalOpen} setIsOpen={setTOSModalOpen} />
    </>
  );
};
