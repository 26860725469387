import React, { FC, createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { Snackbar, SnackbarsWrapper } from './Snackbar';

const SnackbarContext = createContext<((snackbar: SnackbarProps) => SnackbarProps) | undefined>(undefined);

export interface SnackbarProps {
  message: string;
  id?: number;
  variant?: 'success' | 'error';
  timeout?: number;
  remove?: () => void;
  onClose?: () => void;
}

export const SnackbarProvider: FC = ({ children }) => {
  const snackbarTimeout = useRef<number | undefined>(undefined);
  const [snackbars, setSnackbars] = useState<SnackbarProps[] | []>([]);

  const removeSnackbar = (id: SnackbarProps['id']) => {
    setSnackbars((prevSnackbars) => prevSnackbars.filter((s) => s.id !== id));
  };

  const addSnackbar = useCallback(
    ({ message, variant = 'success', timeout = 5000, id = Date.now() }: SnackbarProps) => {
      const snackbar = {
        message,
        variant,
        timeout,
        id,
        remove: () => removeSnackbar(id),
      };

      setSnackbars((prevSnackbars) => [snackbar, ...prevSnackbars]);

      if (timeout) {
        snackbarTimeout.current = window.setTimeout(() => {
          removeSnackbar(id);
        }, timeout);
      }

      return snackbar;
    },
    [setSnackbars]
  );

  useEffect(() => {
    // Clear Timeout on unmount
    return () => {
      if (snackbarTimeout.current) {
        clearTimeout(snackbarTimeout.current);
      }
    };
  }, []);

  return (
    <SnackbarContext.Provider value={addSnackbar}>
      {children}
      <SnackbarsWrapper>
        {snackbars.map((snackbar) => (
          <Snackbar key={snackbar.id} message={snackbar.message} variant={snackbar.variant} onClose={() => removeSnackbar(snackbar.id)} />
        ))}
      </SnackbarsWrapper>
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error('You need to wrap your component with SnackbarProvider');
  }

  return context;
};
