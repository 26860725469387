import React from 'react';
import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';

const Heading = styled.h3`
  color: ${globalTheme.palette.primary};
  font-size: 42px;
  font-weight: 500;
  margin: 20px 0 40px 0;
`;

export const TransformationName = () => <Heading>Video Summarization</Heading>;
