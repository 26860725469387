import { useEffect, useRef } from 'react';

import { DispatchAction } from '../actions';
import { State } from '../state';

// FIXME: use zod
interface RelevanceHistogramSchema {
  data: Array<number>;
  // eslint-disable-next-line camelcase
  segments_secs: Array<Array<number>>;
}

export const useVideoInfoFetch = (dispatch: DispatchAction, videoInfoFetch: State['videoInfoFetch']) => {
  const requestInProgress = useRef(false);

  useEffect(() => {
    if (videoInfoFetch.status === 'pending' && !requestInProgress.current) {
      requestInProgress.current = true;

      void fetch(videoInfoFetch.videoInfoUrl)
        .then((response) => response.json() as Promise<RelevanceHistogramSchema>)
        // eslint-disable-next-line camelcase
        .then(({ data: relevanceHistogram, segments_secs }) => {
          const mappedSegments = segments_secs.map(([fromTimestamp, toTimestamp]) => ({ fromTimestamp, toTimestamp }));
          dispatch({ type: 'videoInfoFetchSucceeded', payload: { cutSegments: mappedSegments, relevanceHistogram } });
        })
        .catch(() => {
          dispatch({ type: 'videoInfoFetchFailed', payload: { errorMessage: 'Unknown error occured' } });
        })
        .finally(() => {
          requestInProgress.current = false;
        });
    }
  }, [dispatch, videoInfoFetch]);
};
