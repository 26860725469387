export const validateLength = (newValue: string, originalLength: null | number) => {
  const parsedValue = parseInt(newValue, 10);

  if (parsedValue.toString() !== newValue) {
    return 'Length must be a number';
  }

  if (parsedValue < 2) {
    return 'Length cannot be less than 2';
  }

  if (parsedValue > 30) {
    return 'Length cannot be more than 30';
  }

  if (originalLength !== null && parsedValue > originalLength) {
    return `Length cannot be more than the original video (${originalLength} seconds)`;
  }

  return undefined;
};
