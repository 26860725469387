import { useMemo } from 'react';
import { Unselect, unselect } from '../unselect';
import { KeyboardHandlers } from './useKeyboardEventHandler';

export interface ListNavigationHandlers<ItemType> {
  items: ItemType[];
  activeItem: ItemType | Unselect;
  onActiveItemChange: (item: ItemType) => void;
}

export const useListNavigationHandlers = <ItemType = string>({
  items,
  onActiveItemChange,
  activeItem,
}: ListNavigationHandlers<ItemType>): KeyboardHandlers => {
  const index = activeItem === unselect ? -1 : items.indexOf(activeItem);
  const end = items.length - 1;
  return useMemo(
    () => ({
      onHome: () => onActiveItemChange(items[0]),
      onEnd: () => onActiveItemChange(items[end]),
      onDown: () => onActiveItemChange(items[(index + 1) % items.length]),
      onUp: () => onActiveItemChange(items[index === -1 ? end : (index - 1 + items.length) % items.length]),
    }),
    [onActiveItemChange, items, end, index]
  );
};
