import { createTheme, createVariant } from '@clds/component-theme';
import { ListProps } from './List';

export const listTheme = createTheme(
  {
    byMaxHeight: {
      md: { maxHeight: '300px' },
      lg: { maxHeight: '420px' },
      none: { maxHeight: 'none' },
    },
  },
  'list'
);

type ThemeProps = Pick<ListProps, 'maxHeight'>;

export const popoverMaxHeightVariant = createVariant((props: ThemeProps) => listTheme.byMaxHeight[props.maxHeight || 'none']);
