import styled, { css } from 'styled-components';
import { mix } from 'polished';
import { combineValues } from '@clds/component-theme';
import { Button } from './Button';
import { buttonTheme } from './buttonTheme';

const openButtonColor = ({ shape, variant }) =>
  combineValues(buttonTheme[shape]?.[variant]?.background?.color, buttonTheme.split?.[variant]?.open?.accentColor, (base, accent) =>
    mix(0.12, accent, base)
  );

const openMenuButtonStyles = css`
  background: ${openButtonColor};
  box-shadow: initial;

  &::before {
    display: none;
  }
`;

export const SecondButton = styled(Button)`
  height: 100%;

  &,
  &:focus:not(:active) {
    ${({ isMenuOpen }) => (isMenuOpen ? openMenuButtonStyles : '')}
  }
`;
