import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { combineValues } from '@clds/component-theme';
import { BaseButton } from './BaseButton';
import { propTypes, TYPE } from './props';
import { buttonTheme } from './buttonTheme';

const hoverCss = ({ shape, buttonType }) => css`
  :hover {
    background-color: ${combineValues(buttonTheme[shape]?.[buttonType]?.background?.color, (color) => rgba(color, 0.06))};
  }
`;

const activeCss = ({ shape, buttonType }) => css`
  :active {
    background-color: ${combineValues(buttonTheme[shape]?.[buttonType]?.background?.color, (color) => rgba(color, 0.12))};
  }
`;

export const ButtonLink = styled(BaseButton).attrs(({ type, nativeType }) => ({ buttonType: type, type: nativeType }))`
  ${(props) =>
    !props.selected &&
    css`
      ${hoverCss(props)};
      ${activeCss(props)};
    `};
`;

ButtonLink.propTypes = propTypes;
ButtonLink.defaultProps = {
  type: TYPE.LINK,
  nativeType: 'button'
};
