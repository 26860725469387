import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { globalTheme, useMixin } from '@clds/component-theme';
import { FileUpload, InfoOutline } from '@clds/icon';
import { Tooltip } from '@clds/tooltip';
import { Button } from '@cld/button-next';
import { State } from '../../state/state';
import { SectionedEventData } from '../../state/effects/useAnalytics';
import arrow from './arrow.svg';
import cloud from './cloud.svg';

interface FileSelectionProps {
  onSelect(file: File): void;
  onOriginalDurationChange(duration: number): void;
  selectedFile?: File;
  videoUpload: State['videoUpload'];
  track: (eventData: SectionedEventData) => void;
}

const Arrow = styled.img`
  @keyframes arrow-flying {
    0% {
      opacity: 0;
      transform: translate3d(-50%, 75px, 0);
    }

    25% {
      opacity: 1;
      transform: translate3d(-50%, 75px, 0);
    }

    75% {
      opacity: 1;
      transform: translate3d(-50%, 30px, 0);
    }

    100% {
      opacity: 0;
      transform: translate3d(-50%, 30px, 0);
    }
  }

  animation-duration: 3s;
  animation-name: arrow-flying;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  left: 50%;
  position: absolute;
  top: 0;
`;

const Cloud = styled.img`
  left: 50%;
  position: absolute;
  top: 35%;
  transform: translate(-50%, -50%);
`;

const FileInput = styled.input`
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const FileName = styled.p`
  color: ${globalTheme.palette.primary};
  font-size: 12px;
  font-weight: 500;
  margin: 10px 0 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Root = styled.div`
  text-align: center;
  margin-left: -8px;
`;

const SelectedVideo = styled.video`
  background-color: #000;
  border: 4px solid ${globalTheme.palette.primary};
  border-radius: 2px;
  height: 100px;
  width: 100px;
`;

const UploadAnimation = styled.div`
  height: 100px;
  position: relative;
  width: 100px;
`;

const UploadButton = styled.button`
  background-color: ${globalTheme.palette.secondaryAlt};
  border: none;
  border-radius: 2px;
  height: 100px;
  position: relative;
  width: 100px;

  &::after {
    background-color: ${globalTheme.palette.surface};
    content: '';
    height: 3px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
  }

  &::before {
    background-color: ${globalTheme.palette.surface};
    content: '';
    height: 20px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3px;
  }
`;

const UploadText = styled.p`
  color: ${globalTheme.palette.contrastLow};
  font-size: 12px;
  margin: 10px 0 4px 0;
  display: flex;
`;

const UploadingText = styled.p`
  color: ${globalTheme.palette.primary};
  font-size: 12px;
  font-weight: 600;
  margin: 10px 0 4px 0;
  text-align: center;
`;

const UseAnotherButton = styled(Button)<{ isVisible: boolean }>`
  position: relative;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const FileSelection = ({ onSelect, onOriginalDurationChange, selectedFile, videoUpload, track }: FileSelectionProps) => {
  const { contrastLow } = useMixin(globalTheme.palette);
  const fileInput = useRef<HTMLInputElement | null>(null);
  const video = useRef<HTMLVideoElement | null>(null);

  const trackUploadButton = () => track({ eventDescription: 'click upload Asset', event: 'asset_upload' });
  const trackUploadAgainButton = () => track({ eventDescription: 'click upload another video', event: 'upload_again' });

  const selectedFileBase64 = useMemo(() => (selectedFile ? window.URL.createObjectURL(selectedFile) : undefined), [selectedFile]);

  useEffect(() => {
    if (!selectedFile && fileInput.current && fileInput.current.files && fileInput.current.files.length > 0) {
      fileInput.current.value = '';
    }
  }, [selectedFile]);

  useEffect(() => {
    const videoRef = video.current;
    const handleLoadedData = () => onOriginalDurationChange(Math.ceil(videoRef?.duration ?? 0));

    videoRef?.addEventListener('loadeddata', handleLoadedData);

    return () => videoRef?.removeEventListener('loadeddata', handleLoadedData);
  }, [videoUpload, onOriginalDurationChange]);

  const handlePickFile = () => {
    const inputValue = (fileInput.current?.files ?? [])[0];

    if (inputValue) {
      onSelect(inputValue);
    }
  };

  return (
    <Root>
      {selectedFile && videoUpload.status !== 'error' ? (
        videoUpload.status === 'pending' ? (
          <>
            <UploadAnimation>
              <Cloud alt="" src={cloud} />
              <Arrow alt="" src={arrow} />
            </UploadAnimation>
            <UploadingText>Uploading...</UploadingText>
          </>
        ) : (
          <>
            <SelectedVideo autoPlay loop muted ref={video} src={selectedFileBase64} />
            <FileName>{selectedFile.name}</FileName>
          </>
        )
      ) : (
        <>
          <UploadButton onClick={trackUploadButton}>
            <FileInput accept="video/*" onChange={handlePickFile} ref={fileInput} type="file" />
          </UploadButton>
          <UploadText>
            <span>Upload your own &nbsp;</span>
            <Tooltip title="Max 5 min">
              <InfoOutline size="md" color={contrastLow} />
            </Tooltip>
          </UploadText>
        </>
      )}

      <UseAnotherButton
        isVisible={!!selectedFile && videoUpload.status === 'success'}
        leftSlot={<FileUpload />}
        size="sm"
        tone="plain"
        variant="secondary"
        onClick={trackUploadAgainButton}
      >
        Use another
        {selectedFile && <FileInput accept="video/*" onChange={handlePickFile} ref={fileInput} type="file" />}
      </UseAnotherButton>
    </Root>
  );
};
