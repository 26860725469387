import { createUseFoundationHook, createUseSurfaceHook, Size } from '@clds/design-system-foundations';
import { ButtonProps, ButtonSize, ButtonTone } from './types';

const componentSizeByFoundationSize: Record<Size, ButtonSize> = {
  xxs: 'sm',
  xs: 'sm',
  sm: 'sm',
  md: 'sm',
  lg: 'sm',
  xl: 'md',
  xxl: 'md',
};

export const { useFoundation } = createUseFoundationHook<Pick<ButtonProps, 'size'>>(
  ({ size }) => ({ size: componentSizeByFoundationSize[size] }),
  ({ size }) => ({ size }),
  { size: 'md' }
);

const createUseSurfaceHookWithDefaultTone = (tone: ButtonTone) =>
  createUseSurfaceHook<Pick<ButtonProps, 'variant' | 'tone'>>(
    ({ variant, tone }) => ({ variant, tone: tone === 'solid' ? 'invert' : 'plain' }),
    ({ variant, tone }) => ({
      variant,
      tone: tone === 'solid' ? 'solid' : 'subtle',
    }),
    { tone, variant: 'primary' }
  );

export const { useSurface: useButtonSurface } = createUseSurfaceHookWithDefaultTone('solid');

export const { useSurface: useLinkSurface } = createUseSurfaceHookWithDefaultTone('plain');
