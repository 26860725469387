import { globalTheme } from '@clds/component-theme';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: ${globalTheme.palette.surfaceAlt};
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 10px;
`;

const Heading = styled.h3`
  color: ${globalTheme.palette.contrastLow};
  font-size: 24px;
  line-height: 1;
  margin: 0 0 5px 0;
`;

const Subheading = styled.p`
  color: ${globalTheme.palette.contrastHigh};
  font-size: 12px;
  line-height: 1;
  margin: 0;
`;

export const TransformationNameBar = () => (
  <Container>
    <Heading>Video Summarization</Heading>
    <Subheading>Powered by Cloudinary AI</Subheading>
  </Container>
);
