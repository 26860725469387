import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DropdownMenu } from '@cld/dropdown-menu';
import { Icon } from '@cld/icon';
import { Button } from './Button';
import { ButtonGroup } from './ButtonGroup';
import { SecondButton } from './SplitButton.styled';

export const SplitButton = ({ children, overlay, variant = 'primary', onClick, ...buttonProps }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = (newMenuState) => {
    setIsMenuOpen(newMenuState);
  };

  return (
    <ButtonGroup variant={variant}>
      <Button onClick={onClick} variant={variant} {...buttonProps}>
        {children}
      </Button>

      <DropdownMenu placement="bottomRight" items={overlay} afterVisibleChange={handleMenuOpen}>
        <SecondButton variant={variant} isMenuOpen={isMenuOpen} {...buttonProps}>
          <Icon name="keyboardArrowDown" />
        </SecondButton>
      </DropdownMenu>
    </ButtonGroup>
  );
};

SplitButton.propTypes = {
  ...Button.propTypes,
  variant: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  overlay: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};
