import React, { FC, useMemo, useRef } from 'react';
import ScrollLock from 'react-scrolllock';
import { StylableComponentProps } from '@clds/common-definitions';
import { InternalModalContext, InternalModalContextType } from '../context';
import { ThemeProps } from '../theme';
import { ModalWrapper, Overlay } from './ModalOverlay.styled';
import { useModalCloseEvents } from './ModalOverlay.hooks';

export interface ModalOverlayProps extends StylableComponentProps, ThemeProps {
  /** Function fired to close Modal */
  onClose: (trackingId?: string) => void;
  /** Allows to fire onClose on ESC */
  closeOnEsc?: boolean;
  /** Allows to fire onClose on click outside the modal */
  closeOnOutsideClick?: boolean;
  dataTestSpecifier?: string;
}

export const ModalOverlay: FC<ModalOverlayProps> = ({
  children,
  className,
  onClose,
  closeOnEsc,
  closeOnOutsideClick,
  wrapperPadding,
  ...rest
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const internalContext = useMemo<InternalModalContextType>(() => ({ onClose }), [onClose]);

  useModalCloseEvents(onClose, closeOnEsc, closeOnOutsideClick, modalRef);

  return (
    <InternalModalContext.Provider value={internalContext}>
      <Overlay {...rest} className={className}>
        <ModalWrapper ref={modalRef} wrapperPadding={wrapperPadding}>
          <ScrollLock />
          {children}
        </ModalWrapper>
      </Overlay>
    </InternalModalContext.Provider>
  );
};
