import { Action } from '../actions';
import { State } from '../state';
import { EventData, generateTrackFn, generateTrackShortVideoScreen, generateTrackWelcomeScreen } from './useAnalytics';


export type TrackingFunction = (eventData: EventData) => void;

export const analyticsMiddleware = (track: TrackingFunction, action: Action, stateBeforeAction: State) => {
  const trackSmartAi = generateTrackFn(track);
  const trackShortVideoScreen = generateTrackShortVideoScreen(trackSmartAi);
  const trackWelcomeScreen = generateTrackWelcomeScreen(trackSmartAi);

  switch (action.type) {
    case 'downloadQueued':
      return trackShortVideoScreen({
        eventDescription: 'download',
        event: 'short_video_download',
      });

    case 'chooseRating1':
      return trackShortVideoScreen({
        eventDescription: 'choose rating 1',
        event: 'rating_select_score',
        additionalInfo: '1',
      });

    case 'chooseRating2':
      return trackShortVideoScreen({
        eventDescription: 'choose rating 2',
        event: 'rating_select_score',
        additionalInfo: '2',
      });

    case 'chooseRating3':
      return trackShortVideoScreen({
        eventDescription: 'choose rating 3',
        event: 'rating_select_score',
        additionalInfo: '3',
      });

    case 'chooseRating4':
      return trackShortVideoScreen({
        eventDescription: 'choose rating 4',
        event: 'rating_select_score',
        additionalInfo: '4',
      });

    case 'chooseRating5':
      return trackShortVideoScreen({
        eventDescription: 'choose rating 5',
        event: 'rating_select_score',
        additionalInfo: '5',
      });

    case 'videoPreviewFailed':
      return trackShortVideoScreen({
        eventDescription: 'error message',
        event: 'error_msg',
        additionalInfo: 'video preview failed',
      });

    case 'videoInfoFetchFailed':
      return trackShortVideoScreen({
        eventDescription: 'error message',
        event: 'error_msg',
        additionalInfo: 'video info fetch failed',
      });

    case 'fileSelected':
      return trackWelcomeScreen({
        eventDescription: 'when upload status changed to "uploading..."',
        event: 'asset_upload_progress',
      });

    case 'lengthChanged':
      return trackWelcomeScreen({
        eventDescription: 'change summarization video length',
        event: 'video_length_change',
        additionalInfo: stateBeforeAction.selectedSource?.type === 'preset' ? stateBeforeAction.selectedSource.preset : 'custom',
      });

    case 'presetSelected':
      return trackWelcomeScreen({
        eventDescription: 'choose demo asset',
        event: 'demo_video_select',
      });

    case 'setupSubmitted':
      return trackWelcomeScreen({
        eventDescription: 'click try now',
        event: 'try_now_button_click',
      });

    case 'videoUploadSucceeded':
      return trackWelcomeScreen({
        eventDescription: 'upload successful',
        event: 'asset_upload_success',
      });

    case 'videoUploadFailed':
      return trackWelcomeScreen({
        eventDescription: 'error message',
        event: 'error_msg',
        additionalInfo: 'video upload failed',
      });

    default:
  }
};
