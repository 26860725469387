import { useCallback, useEffect, useRef } from 'react';

export const useRenderLoop = (callback: () => void) => {
  const renderCycleHandle = useRef<number | undefined>(undefined);

  const loopedCallback = useCallback(() => {
    callback();

    renderCycleHandle.current = requestAnimationFrame(loopedCallback);
  }, [callback]);

  useEffect(() => {
    renderCycleHandle.current = requestAnimationFrame(loopedCallback);

    return () => {
      if (renderCycleHandle.current) {
        cancelAnimationFrame(renderCycleHandle.current);
      }
    };
  }, [loopedCallback]);
};
