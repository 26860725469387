import { css } from 'styled-components';
import { DeepMap } from '@clds/common-definitions';
import { GlobalTheme } from '@clds/component-theme';
import { blenderCss, blenderForeground, BlenderProps, BlendIntensity, BlendMode } from '@clds/blender';
import { BaseBlenderProps, ButtonBlenderProps, ButtonTone } from './types';

type SelectionState = 'selected' | 'unselected';
type InteractionState = 'normal' | 'hover' | 'active';

const intensityByState: DeepMap<[SelectionState, InteractionState], BlendIntensity> = {
  selected: {
    normal: 'lg',
    hover: 'xl',
    active: 'xl',
  },
  unselected: {
    normal: 'md',
    hover: 'sm',
    active: 'lg',
  },
} as const;

const modeByTone: Record<ButtonTone, BlendMode> = {
  surface: 'surfaceSolid',
  solid: 'solidContrast',
  plain: 'plainSolid',
  invert: 'plainInvert',
};

export const toBlenderProps = (selectionState: SelectionState, interactionState: InteractionState, { variant, tone }: BaseBlenderProps): BlenderProps => ({
  variant,
  intensity: intensityByState[selectionState][interactionState],
  mode: modeByTone[tone],
});

export const createBlenderMappingForSelectionState = (selectionState: SelectionState) => (props: BaseBlenderProps) => css`
  ${blenderCss.populateVariables(toBlenderProps(selectionState, 'normal', props))};
  &:hover {
    ${blenderCss.populateVariables(toBlenderProps(selectionState, 'hover', props))};
  }
  &:active {
    ${blenderCss.populateVariables(toBlenderProps(selectionState, 'active', props))};
  }
`;

export const buttonBlenderMapping = (props: ButtonBlenderProps) =>
  createBlenderMappingForSelectionState(props.isSelected ? 'selected' : 'unselected')(props);

export const focusBorderColorBlenderMapping = ({ theme, variant, tone }: BaseBlenderProps & { theme: GlobalTheme }) =>
  blenderForeground({ mode: tone === 'solid' ? 'solidContrast' : 'surfaceSolid', variant, theme });
