import React, { useMemo } from 'react';
import styled from 'styled-components';

import { globalTheme, useMixin } from '@clds/component-theme';
import { Mute, Play } from '@clds/icon';
import { BaseSkeleton, TextSkeletonProps } from '@cld/skeleton';
import { ControlButton } from './VideoPreview';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import animationGif from './animation.gif';

interface VideoProcessingInfoProps {
  textSeed: number;
}

const Animation = styled.img`
  height: 250px;
  width: 250px;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  margin: 0 auto;
`;

const SkeletonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const Text = styled.p`
  color: ${globalTheme.palette.contrastLow};
  font-size: 24px;
  font-weight: 700;
  margin: 36px 0 0 0;
`;

interface SkeletonWithDimensions extends TextSkeletonProps {
  width?: number;
  height?: number;
}

const SkeletonWrapper = styled(BaseSkeleton)<SkeletonWithDimensions>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? height : 60)}px;
  margin: 10px 0;
`;

const Spacer = styled.div`
  height: 20px;
`;

const ControlsBarContainer = styled.div`
  height: 50px;
  background-color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;

  > div:first-child {
    display: flex;
    align-items: center;
  }
`;

const textOptions = ['Your summary will be ready in a few moments', "Your video is processing. Won't be long", "Hold tight, we're working on it"];

export const VideoProcessingInfo = ({ textSeed }: VideoProcessingInfoProps) => {
  const currentTextOption = useMemo(() => textOptions[textSeed % textOptions.length], [textSeed]);
  const surface = useMixin(globalTheme.palette.surface);

  return (
    <Container>
      <Animation alt="" src={animationGif as string} />
      <Text>{currentTextOption}</Text>

      <SkeletonContainer>
        <ControlsBarContainer>
          <div>
            <ControlButton leftSlot={<Play />} isDisabled size="md" variant="overlay" />
            <ControlButton leftSlot={<Mute />} isDisabled size="md" variant="overlay" />
            <SkeletonWrapper width={80} height={30} baseColor={surface} isLoading />
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <SkeletonWrapper width={130} height={30} baseColor={surface} isLoading />
          </div>
          <SkeletonWrapper width={100} height={30} baseColor={surface} isLoading />
        </ControlsBarContainer>
        <SkeletonWrapper baseColor={surface} isLoading />
        <Spacer />
        <SkeletonWrapper width={180} height={30} baseColor={surface} isLoading />
        <SkeletonWrapper baseColor={surface} isLoading />
      </SkeletonContainer>
    </Container>
  );
};
