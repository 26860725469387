import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useMixin } from '@clds/component-theme';
import { Fade } from '@cld/transitions';
import { ModalOverlay, ModalOverlayProps } from './ModalOverlay/ModalOverlay';
import { modalNextTheme } from './theme';

const StyledFade = styled(Fade)`
  /** Prevents stacking context issues, see https://stackoverflow.com/a/11742116 */
  position: fixed;
  z-index: ${modalNextTheme.overlay.zIndex};
`;

export interface ModalProps extends Omit<ModalOverlayProps, 'transitionStatus'> {
  /** Enables or disables the modal */
  isOpen: boolean;
  /** Optional custom node to portal to */
  customNode?: HTMLModElement;
}

/**
 * Modal uses React portal to appear in the different DOM node.
 * it will mount itself inside the div at the end of the `<body>` by default
 * it can take DOM node to portal as well using `customNode` prop.
 */
export const Modal: FC<ModalProps> = ({ children, isOpen, customNode, className, ...modalOverlayProps }) => {
  const timeout = useMixin(modalNextTheme.fadeDuration);

  return createPortal(
    <StyledFade timeout={timeout} in={isOpen} data-test="fade" className={className}>
      <ModalOverlay {...modalOverlayProps} data-test="modal-overlay">
        {children}
      </ModalOverlay>
    </StyledFade>,
    customNode || document.body
  );
};
