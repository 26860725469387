import Tooltip from 'rc-tooltip';
import React, { FC, ReactElement } from 'react';
import { TooltipProps } from 'rc-tooltip/es/Tooltip';
import { PaperProps } from '@clds/paper';
import { popoverProps } from './Popover.props';
import { Global, StyledPaper } from './Popover.styles';
import { builtinPlacements, PopoverPlacement } from './placements';

export interface PopoverProps extends Omit<TooltipProps, 'placement'> {
  paperProps?: PaperProps;
  children: ReactElement;
  placement?: PopoverPlacement;
  getTooltipContainer?: (node: HTMLElement) => HTMLElement;
  minWidth?: 'sm' | 'md' | 'lg' | 'xl' | 'none';
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | 'none';
  'data-test-specifier'?: string;
}

export const Popover: FC<PopoverProps> = ({
  paperProps,
  overlay,
  getTooltipContainer,
  minWidth,
  maxWidth,
  'data-test-specifier': dataTestSpecifier = '',
  ...rest
}) => (
  <>
    <Global id="global-popover-styles" />
    <Tooltip
      {...rest}
      builtinPlacements={builtinPlacements}
      prefixCls="cld-popover"
      getTooltipContainer={getTooltipContainer}
      overlay={
        <StyledPaper
          isPadded
          maxWidth={maxWidth}
          minWidth={minWidth}
          borderRadius="md"
          {...paperProps}
          data-test-specifier={`popover${dataTestSpecifier ? '-' + dataTestSpecifier : ''}`}
        >
          {overlay}
        </StyledPaper>
      }
    />
  </>
);

Popover.propTypes = popoverProps;
Popover.defaultProps = {
  animation: 'zoom',
};
