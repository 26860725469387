import { createTheme, createVariant, globalTheme } from '@clds/component-theme';

export const modalNextTheme = createTheme({
  overlay: {
    zIndex: 9000,
    background: globalTheme.palette.overlayDark2,
  },
  fadeDuration: 300,
  wrapperPaddingVariant: {
    md: { wrapper: { padding: globalTheme.spacing.md } },
    none: { wrapper: { padding: '0' } },
  },
});

export interface ThemeProps {
  /**
   * Allows to remove wrapper padding. It is needed for fullscreen modal layouts.
   * By default it is set to "md" in order to avoid regression of https://cloudinary.atlassian.net/browse/APPS-5831
   * Use this property only if you need fullscreen modal layout
   * @deprecated
   * @experimental
   * */
  wrapperPadding?: 'md' | 'none';
}

export const wrapperPaddingVariant = createVariant((props: ThemeProps) => modalNextTheme.wrapperPaddingVariant[props.wrapperPadding || 'md']);
