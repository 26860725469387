import { createTheme, createVariant, globalTheme } from '@clds/component-theme';
import { ButtonThemeProps } from './types';

export const buttonTheme = createTheme({
  bySize: {
    md: {
      typographySize: 'sm',
      paddingH: globalTheme.spacing.sm,
      height: '2.5rem',
      foundationSize: 'md',
    },
    sm: {
      typographySize: 'xs',
      paddingH: globalTheme.spacing.xs,
      height: '1.75rem',
      foundationSize: 'sm',
    },
  },
  byShape: {
    normal: {
      radius: globalTheme.radius.unstable_new.md,
    },
    pill: {
      radius: '100px', // to create a pill button, we need to provide radius which is always > button.height / 2
    },
  },
});

export const buttonNextSizeVariant = createVariant((props: Pick<ButtonThemeProps, 'size'>) => buttonTheme.bySize[props.size]);
export const shapeVariant = createVariant((props: Pick<ButtonThemeProps, 'shape'>) => buttonTheme.byShape[props.shape]);
