import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { MainPage } from './mainPage/MainPage';
import { useStateManagement } from './state/useStateManagement';
import { useAnalytics } from './state/effects/useAnalytics';
import { ResultsPage } from './resultsPage/ResultsPage';
import { MobilePage } from './mobilePage/MobilePage';
import { PresetName } from './state/presets';
import { UserRating } from './components/survey/RatingSurvey';

interface RootPageProps {
  analytics(eventData: { additionalInfo?: string; eventDescription: string; event: string; mainScreenName: string; productName: string }): void;
  clock(): Date;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const RootPage = ({ analytics, clock }: RootPageProps) => {
  const { dispatch, state } = useStateManagement({ clock, track: analytics });
  const { trackShortVideoScreen, trackWelcomeScreen } = useAnalytics(analytics);

  const handleVideoDownloadClick = () => dispatch({ type: 'downloadQueued', payload: {} });
  const handleFileSelect = (newFile: File) => dispatch({ type: 'fileSelected', payload: { newFile } });
  const handleLengthChange = (newValue: string) => dispatch({ type: 'lengthChanged', payload: { newValue } });
  const handlePresetSelect = (newPreset: PresetName) => dispatch({ type: 'presetSelected', payload: { newPreset } });
  const handleSubmit = () => dispatch({ type: 'setupSubmitted', payload: {} });
  const handleRatingSelected = (rating: UserRating) => dispatch({ type: `chooseRating${rating}`, payload: { rating } });
  const handleOriginalDurationChange = (duration: number) => dispatch({ type: 'videoOriginalDuration', payload: { duration } });
  const handleReset = () => dispatch({ type: 'resetState', payload: {} });

  return (
    <Root>
      {isMobile ? (
        <MobilePage track={trackWelcomeScreen} />
      ) : (
        <>
          {state.page === 'main' && (
            <MainPage
              isSubmitInProgress={state.videoPreview.status === 'pending'}
              lengthErrorMessage={state.lengthErrorMessage}
              lengthValue={state.lengthValue}
              onFileSelect={handleFileSelect}
              onLengthChange={handleLengthChange}
              onPresetSelect={handlePresetSelect}
              onSubmit={handleSubmit}
              onOriginalDurationChange={handleOriginalDurationChange}
              selectedFile={state.selectedSource?.type === 'file' ? state.selectedSource.file : undefined}
              selectedPreset={state.selectedSource?.type === 'preset' ? state.selectedSource.preset : undefined}
              videoUpload={state.videoUpload}
              track={trackWelcomeScreen}
            />
          )}

          {state.page === 'results' && state.videoPreview.status === 'success' && (
            <ResultsPage
              originalLengthSeconds={state.videoPreview.originalLengthSeconds}
              onVideoDownloadClick={handleVideoDownloadClick}
              pollingTextSeed={state.pollingTextSeed}
              videoInfoFetch={state.videoInfoFetch}
              videoPreview={state.videoPreview}
              videoPollingStatus={state.videoPolling.status}
              rating={state.rating}
              onRatingSelected={handleRatingSelected}
              desiredLengthValue={state.lengthValue}
              resetState={handleReset}
              track={trackShortVideoScreen}
            />
          )}
        </>
      )}
    </Root>
  );
};
