import { createContext } from 'react';
import { ItemStyleProps } from '@clds/item';
import { createUseDefinedContext } from '../../utils/hooks/createUseDefinedContext';
import { createPropsContextProvider } from '../../utils/createPropsContextProvider';

const listStyleContext = createContext<Partial<ItemStyleProps> | undefined>(undefined);

export const ListStyleProvider = createPropsContextProvider(listStyleContext);

export const useListStyle = createUseDefinedContext(listStyleContext, 'Please wrap your component with ListStyleProvider');
