import React, { ReactNode, useRef } from 'react';
import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';
import { usePlayOnHover } from './usePlayOnHover';

interface PresetEntryProps {
  isActive: boolean;
  label: ReactNode;
  onClick(): void;
  videoUrl: string;
}

const Button = styled.button<{ isActive: boolean }>`
  background-color: transparent;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  padding: 0;
  position: relative;

  &::after {
    border: 4px solid ${globalTheme.palette.primary};
    border-radius: 2px;
    content: '';
    height: calc(100% + 8px);
    left: -4px;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    position: absolute;
    top: -4px;
    transition: opacity 0.5s;
    width: calc(100% + 8px);
  }
`;

const Name = styled.p`
  color: ${globalTheme.palette.contrastLow};
  font-size: 12px;
  margin: 10px 0 0 0;
`;

const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: ${globalTheme.spacing.sm};
`;

const Video = styled.video`
  background-color: #000;
  height: 100%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
`;

const VideoCropper = styled.div`
  height: 100px;
  overflow: hidden;
  position: relative;
  width: 100px;
`;

export const PresetEntry = ({ isActive, label, onClick, videoUrl }: PresetEntryProps) => {
  const container = useRef<HTMLDivElement | null>(null);
  const video = useRef<HTMLVideoElement | null>(null);

  usePlayOnHover(video, container);

  return (
    <Root ref={container}>
      <Button isActive={isActive} onClick={onClick}>
        <VideoCropper>
          <Video muted ref={video} src={videoUrl} />
        </VideoCropper>
      </Button>
      <Name>{label}</Name>
    </Root>
  );
};
