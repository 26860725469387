import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Progress from '@clds/progress';
import { WrapTextChildren } from '@cld/component-enhancer';
import { propTypes, defaultProps } from '../props';
import { StyledBaseButton } from './StyledBaseButton';
import { StyledProgress } from './StyledProgress';

export const BaseButton = styled(StyledBaseButton).attrs((props) => {
  const { size, progress, children, className, disabled, selected } = props;

  return {
    children: (
      <>
        {progress && (
          <StyledProgress>
            <Progress.Circular size={size === 'sm' ? 'xs' : 'md'} />
          </StyledProgress>
        )}
        <WrapTextChildren>{children}</WrapTextChildren>
      </>
    ),
    className: classNames(className, {
      progress,
      disabled,
      selected,
    }),
  };
})``;

BaseButton.propTypes = propTypes;
BaseButton.defaultProps = defaultProps;
