export type PresetName = 'lifestyle' | 'motorization' | 'travel' | 'sports';

export const presets: {
  [key in PresetName]: {
    label: string;
    originalLengthSeconds: number;
    previewThumbnailUrl: string;
    publicId: string;
  };
} = {
  lifestyle: {
    label: 'Lifestyle',
    originalLengthSeconds: 59,
    previewThumbnailUrl: 'https://res.cloudinary.com/smart-ai-transformations/video/upload/v1671032652/presets/lifestyle_rwjiqm.mp4',
    publicId: 'presets/lifestyle_rwjiqm',
  },
  motorization: {
    label: 'Motorization',
    originalLengthSeconds: 75,
    previewThumbnailUrl: 'https://res.cloudinary.com/smart-ai-transformations/video/upload/v1671032399/presets/motorization_ry9zjw.mp4',
    publicId: 'presets/motorization_ry9zjw',
  },
  travel: {
    label: 'Travel',
    originalLengthSeconds: 41,
    previewThumbnailUrl: 'https://res.cloudinary.com/smart-ai-transformations/video/upload/v1671031199/presets/travel_hngrar.mp4',
    publicId: 'presets/travel_hngrar',
  },
  sports: {
    label: 'Sports',
    originalLengthSeconds: 48,
    previewThumbnailUrl: 'https://res.cloudinary.com/smart-ai-transformations/video/upload/v1671031388/presets/sports_mvjwmn.mp4',
    publicId: 'presets/sports_mvjwmn',
  },
} as const;
