import PropTypes from 'prop-types';

export const SHAPE = {
  SOLID: 'solid',
  PILL: 'pill',
};

export const SIZE = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
};

export const TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
  LINK: 'link',
  MENU: 'menu',
};

export const VARIANT = {
  PRIMARY: TYPE.PRIMARY,
  SECONDARY: TYPE.SECONDARY,
  ERROR: TYPE.ERROR,
  WARNING: TYPE.WARNING,
  SUCCESS: TYPE.SUCCESS,
};

export const defaultProps = {
  size: SIZE.MD,
  progress: false,
  disabled: false,
  selected: false,
  shape: SHAPE.SOLID,
};

export const propTypes = {
  className: PropTypes.string,
  progress: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZE)),
  shape: PropTypes.oneOf(Object.values(SHAPE)),
  type: PropTypes.oneOf(Object.values(TYPE)),
};
