import React, { FC } from 'react';
import { blenderCss } from '@clds/blender';
import { useFoundationContext } from '@clds/design-system-foundations';
import Progress, { LogoProgressProps } from '@clds/progress';
import { Button } from './Button';
import { ButtonProps } from './types';

export interface ProgressButtonProps extends Omit<ButtonProps, 'leftSlot'> {
  showSpinner?: boolean;
}

export const ProgressButtonSpinner: FC<Partial<LogoProgressProps>> = ({ size, strokeWidth, color1, color2, ...rest }) => {
  const context = useFoundationContext();
  return (
    <Progress.Logo
      {...rest}
      size={size ?? context?.size ?? /* istanbul ignore next */ 'sm'}
      strokeWidth={strokeWidth ?? '11'}
      color1={color1 ?? blenderCss.foregroundFromCssVariables}
      color2={color2 ?? blenderCss.foregroundFromCssVariables}
    />
  );
};

export const ProgressButton: FC<ProgressButtonProps> = ({ showSpinner = false, ...rest }) => (
  <Button leftSlot={showSpinner && <ProgressButtonSpinner />} {...rest} />
);
