import { transform, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { asStyledComponent } from '@cld/component-enhancer';
import iconMixin from './iconMixin';
import * as icons from './icons';

const StyledIcon = styled.svg`
  display: inline-block;
  vertical-align: top;
  ${iconMixin};
`;

const StyledPath = styled.path`
  fill: ${(props) => (props.variant ? globalTheme.palette[props.variant] : props.color ? props.color : 'currentColor')};
`;

const Icon = asStyledComponent(
  React.forwardRef(({ name, variant, color, ...props }, ref) => (
    <StyledIcon data-test="icon" {...props} viewBox="0 0 24 24" preserveAspectRatio="xMidYMid" ref={ref}>
      {[].concat(icons[name]).map((path, idx) => (
        <StyledPath variant={variant} color={color} d={path} key={`${name}-${idx}`} />
      ))}
    </StyledIcon>
  ))
);

Icon.displayName = 'Icon';

const Icons = transform(icons, (res, _, name) => {
  // Todo this is temporary. This whole section will be removed in PR 1480
  //istanbul ignore next
  const wrappedIcon = (props) => <Icon name={name} {...props} />;
  const iconName = upperFirst(name);
  wrappedIcon.displayName = `Icon.${iconName}`;

  return (res[iconName] = wrappedIcon);
});

Object.assign(Icon, Icons);

Icon.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  name: PropTypes.string,
};

export { Icon };
