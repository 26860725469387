import { createChildDataApi } from '@cld/compound-components';

export interface MenuChildrenData {
  onClick?: () => void; // no parameter, sometimes event is triggered programmatically
}

export const childDataApi = createChildDataApi<MenuChildrenData>('dropdown menu items');
export const useChildRegistry = childDataApi.useChildRegistry;
export const ChildDataRegistryProvider = childDataApi.ChildDataRegistryProvider;
export const useRegisterChildData = childDataApi.useRegisterChildData;
