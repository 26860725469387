import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { SectionTitle } from '../sectionTitle/SectionTitle';
import { RatingSurvey, SurveyRating, UserRating } from './RatingSurvey';

export interface SurveyProps {
  rating: SurveyRating;
  onRatingSelected: (rating: UserRating) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${globalTheme.palette.contrastHigh};
  margin-bottom: 40px;
`;

const Index = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const IndexTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
`;

export const Survey = ({ rating, onRatingSelected }: SurveyProps) => (
  <Container>
    <SectionTitle>Rate the result</SectionTitle>
    <RatingSurvey rating={rating} onSelect={onRatingSelected} />
    <Index>
      <IndexTitle>
        Very
        <br />
        unsatisfied
      </IndexTitle>
      <IndexTitle>
        Very
        <br />
        satisfied
      </IndexTitle>
    </Index>
  </Container>
);
