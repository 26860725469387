import { Context, useContext } from 'react';

/**
 * useContext always return defined value. Otherwise it throws an error.
 * @param context the context to use in a hook
 * @param errorMessage the message of error thrown in case of context was not found in react tree.
 */
export const createUseDefinedContext = <T extends unknown>(context: Context<T | undefined>, errorMessage: string) => () => {
  const contextValue = useContext(context);
  if (contextValue === undefined) {
    throw new Error(errorMessage);
  }
  return contextValue;
};
