/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-extra-non-null-assertion */

import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { globalTheme, overrideTheme, ThemeWithOverrides } from '@clds/component-theme';
import { typographyMixin, TypographyType } from '@clds/typography';

import { Button as OriginalButton, DefaultButtonProps } from '..';
import { buttonTheme } from '../buttonTheme';

const buttonOverride = {
  primary: {
    background: {
      color: globalTheme.palette.primary,
    },
  },
  secondary: {
    background: {
      color: globalTheme.palette.brand.accentCoral,
    },
    typography: {
      type: 'contrastInvert',
    },
  },
};

const themeOverride = (theme?: ThemeWithOverrides) => ({
  ...theme,
  components: {
    ...theme?.components,
    ...overrideTheme(buttonTheme, {
      pill: buttonOverride,
    }),
  },
});

export const ButtonWithLinkStyles = styled(OriginalButton)<DefaultButtonProps>`
  :visited {
    ${(props) => {
      const { size, shape, variant } = props;
      const typographyType = buttonTheme[shape!]?.[variant!]?.typography?.type(props) as TypographyType;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const typographyStyle = typographyMixin(size!, typographyType);
      return css`
        ${typographyStyle};
      `;
    }}
  }
`;

const ButtonLink = (props: DefaultButtonProps & Partial<HTMLAnchorElement>) => (
  <ThemeProvider theme={themeOverride}>
    <ButtonWithLinkStyles
      {...{
        // Reason why spreading props: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/41316
        as: 'a',
      }}
      {...props}
    />
  </ThemeProvider>
);

const Button = (props: DefaultButtonProps) => (
  <ThemeProvider theme={themeOverride}>
    <ButtonWithLinkStyles {...props} />
  </ThemeProvider>
);

export const overrides = { Button, ButtonLink };
