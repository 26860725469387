import styled from 'styled-components';
import { media } from '@clds/style-utils';
import { modalNextTheme, ThemeProps, wrapperPaddingVariant } from '../theme';

export const Overlay = styled.div`
  position: fixed;
  z-index: ${modalNextTheme.overlay.zIndex};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${modalNextTheme.overlay.background};
`;

export const ModalWrapper = styled.div<ThemeProps>`
  background-color: transparent;
  padding: ${wrapperPaddingVariant((variant) => variant.wrapper.padding)};
  max-width: 100%;

  @media ${media.phone()} {
    padding: 0;
  }
`;
