import styled from 'styled-components';
import { media } from '@clds/style-utils';
import { modalLayoutTheme } from '../theme';

export const ModalCard = styled.div`
  max-height: calc(100vh - 2 * ${modalLayoutTheme.layout.box.margin});
  max-height: -webkit-fill-available;
  max-width: calc(100vw - 2 * ${modalLayoutTheme.layout.box.margin});
  border-radius: ${modalLayoutTheme.border.radius};
  background-color: ${modalLayoutTheme.layout.box.background.color};
  margin: ${modalLayoutTheme.layout.box.margin};
  box-shadow: 0 2px 23px 7px rgba(0, 0, 0, 0.29);
  overflow-y: auto;

  @media ${media.desktop({ minOnly: true })} {
    max-height: calc(100vh - 6 * ${modalLayoutTheme.layout.box.margin});
  }

  @media ${media.phone()} {
    max-height: 100vh;
    max-height: -webkit-fill-available;
    max-width: initial;
    margin: 0;
  }
`;
