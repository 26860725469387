import { createContext, useEffect, useMemo } from 'react';
import { createUseDefinedContext } from './hooks/createUseDefinedContext';

/**
 * An utility that returns api to manage context registry of children data.
 * This is needed when parent needs to know some information from deep children.
 * Thanks to this we can define some props directly on children and use them in parent.
 * For example menu items may define if menu should be closed after clicking them.
 * Then keyboard navigation which happens on parent can read if the item of given id is configured to hide menu
 * @param friendlyName a name that will be helpful to identify missing context
 */
export const createChildDataApi = <T extends unknown, K = unknown>(friendlyName: string) => {
  const context = createContext<Map<K, T> | undefined>(undefined);
  const useRegistry = createUseDefinedContext(context, `child data registry provider for '${friendlyName}' not found in context`);
  const useRegisterChildData = (id: K, data: T) => {
    const childData = useRegistry();
    useEffect(() => {
      if (childData.has(id)) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Cannot register child data of id ${id} for registry of name ${friendlyName}. Key already exists`);
      }
      childData.set(id, data);
      return () => {
        childData.delete(id);
      };
    }, [childData, data, id]);
  };
  const useChildRegistry = () => useMemo(() => new Map<K, T>(), []);

  return { useRegisterChildData, useChildRegistry, ChildDataRegistryProvider: context.Provider };
};
