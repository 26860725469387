import React, {
	FC,
	ComponentType,
} from 'react';
import { lighten } from 'polished';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';
import { combineValues, globalTheme } from '@clds/component-theme';
import { avatarTheme } from '@cld/avatar';
import { skeletonTheme } from './theme';
import { TextSkeletonProps, CircleSkeletonProps } from './Skeleton.types';

const backgroundWithGradient = (color: string) => css`
  background-color: ${color};
  background-image: linear-gradient(90deg, ${color}, ${combineValues(skeletonTheme.lighten, (amount) => lighten(amount, color))}, ${color});
`;

const getSize = (props: CircleSkeletonProps & { theme: Record<string, unknown>}) => (props.size && avatarTheme[props.size]?.width?.(props)) || '100%';

const StyledSkeleton = styled.div<TextSkeletonProps | CircleSkeletonProps>`
  width: 100%;
  height: 100%;

  > span {
    height: 100%;
    > span {
      display: block;
      height: 100%;
      margin-bottom: ${globalTheme.spacing.xxs};
      ${({ baseColor, ...rest }) => backgroundWithGradient(baseColor || skeletonTheme.base(rest))};

      ${({ circle }) =>
        circle &&
        css`
          width: ${getSize};
          height: ${getSize};
          border-radius: 100%;
        `};
    }
  }
`;

export const BaseSkeleton: FC<TextSkeletonProps> = (props) => (
  <StyledSkeleton {...props}>
    <Skeleton circle={props.circle} />
  </StyledSkeleton>
);

export const TextSkeleton: FC<TextSkeletonProps> =
	({ isLoading, children = null, ...rest }) =>
		<> { (isLoading ? <BaseSkeleton {...rest} /> : children) }</>;

export const CircleSkeleton: FC<CircleSkeletonProps> =
	({ isLoading, children, ...rest }) =>
		<> { (isLoading ? <BaseSkeleton circle {...rest} /> : children) }</>;

export const withTextSkeleton: <T>(WrapperComponent: ComponentType<T | TextSkeletonProps>) => FC<TextSkeletonProps> =
	(WrappedComponent)=>
		({ isLoading, ...props }) => (
		<>
			{ isLoading && <TextSkeleton isLoading {...props} /> }
			<WrappedComponent isLoading={isLoading} style={{ display: isLoading ? 'none' : undefined }} {...props} />
		</>
	);

export const withCircleSkeleton: <T>(
	WrappedComponent: ComponentType<T | CircleSkeletonProps>) => FC<CircleSkeletonProps> =
	(WrappedComponent)=>
		({ isLoading, ...props }) => (
			<CircleSkeleton isLoading={isLoading} circle {...props}>
				<WrappedComponent isLoading={ isLoading } {...props} />
			</CircleSkeleton>
	);
