import React, { FC, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { StylableComponentProps } from '@clds/common-definitions';
import { globalTheme } from '@clds/component-theme';
import { Cross } from '@clds/icon';
import { InternalModalContext } from '@cld/modal-next';
import { ButtonMenu } from '@cld/button';
import { modalLayoutTheme } from '../theme';

const StyledButton = styled(ButtonMenu)`
  margin-left: ${modalLayoutTheme.layout.spacing.horizontal};
  transform: translate(${globalTheme.spacing.xs}, -50%);
`;

const IconWrapper = styled.div`
  height: 0;
`;

export const ModalCloseIcon: FC<StylableComponentProps> = ({ className }) => {
  const { onClose } = useContext(InternalModalContext);
  const handleOnClose = useCallback(() => onClose('header-close-button'), [onClose]);

  return (
    <IconWrapper onClick={handleOnClose}>
      <StyledButton className={className} data-testid="header-close-button" data-test-specifier="modal-close-icon">
        <Cross />
      </StyledButton>
    </IconWrapper>
  );
};
