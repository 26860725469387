import PropTypes from 'prop-types';
import { popoverPlacements } from './placements';

const triggerType = PropTypes.oneOf(['hover', 'click', 'focus']);

export const popoverProps = {
  trigger: PropTypes.oneOfType([triggerType, PropTypes.arrayOf(triggerType.isRequired)]),
  defaultVisible: PropTypes.bool,
  visible: PropTypes.bool,
  placement: PropTypes.oneOf(popoverPlacements),
  transitionName: PropTypes.string,
  animation: PropTypes.string,
  onVisibleChange: PropTypes.func,
  afterVisibleChange: PropTypes.func,
  overlay: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  overlayStyle: PropTypes.object,
  overlayClassName: PropTypes.string,
  prefixCls: PropTypes.string,
  mouseEnterDelay: PropTypes.number,
  mouseLeaveDelay: PropTypes.number,
  getTooltipContainer: PropTypes.func,
  destroyTooltipOnHide: PropTypes.bool,
  align: PropTypes.object,
  arrowContent: PropTypes.node,
  id: PropTypes.string,
  children: PropTypes.element.isRequired,
  popupVisible: PropTypes.bool,
  paperProps: PropTypes.object,
};
