import React, { Context, FC, useMemo } from 'react';
import memoizeOne from 'memoize-one';
import { identity } from 'lodash';
import shallowEqual from 'shallowequal';

/**
 * The provider accepts context values as props, not just single value prop.
 * All props are combined into context value using shallow-equal memoization.
 * So instead <Provider value={{propA:1, propB:2, propC:3}}>...
 * you can use
 * <Provider propA={1} propB={2} propC={3}/>
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const createPropsContextProvider = <T extends {}>(context: Context<T | undefined>) => {
  const Provider: FC<T> = ({ children, ...rest }) => {
    const getMemoizedValue = useMemo(() => memoizeOne(identity, ([oldValue], [newValue]) => shallowEqual(oldValue, newValue)), []);
    return <context.Provider value={getMemoizedValue(rest) as T}>{children}</context.Provider>;
  };
  return Provider;
};
