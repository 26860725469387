import React from 'react';
import styled from 'styled-components';
import { Button } from '@cld/button-next';

export type UserRating = 1 | 2 | 3 | 4 | 5;
export type SurveyRating = null | UserRating;

interface RatingSurveyProps {
  rating: SurveyRating;
  onSelect: (rating: UserRating) => void;
}

const CirclesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

const CircleButton = styled(Button)`
  width: ${({ size }) => (size === 'md' ? '2.5rem' : '1.75rem')};
  justify-content: center;
  border-radius: 100%;
`;

const getVariant = (isSelected: boolean) => (isSelected ? 'primary' : 'overlay');
const values: UserRating[] = [1, 2, 3, 4, 5];

export const RatingSurvey = ({ rating, onSelect }: RatingSurveyProps) => (
  <CirclesContainer>
    {values.map((value) => (
      <CircleButton key={value} variant={getVariant(rating === value)} size="md" type="button" onClick={() => onSelect(value)} children={value} />
    ))}
  </CirclesContainer>
);
