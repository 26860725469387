import React, { FC, ReactNode } from 'react';
import { StylableComponentProps } from '@clds/common-definitions';
import { BlenderProps } from '@clds/blender';
import { FoundationContextProvider, SurfaceContextProvider, Tone, Variant } from '@clds/design-system-foundations';
import { Check, Cross, Error, Info, Warning } from '@clds/icon';
import { asStyledComponent } from '@cld/component-enhancer';
import { ToastContent, ToastRoot, CloseButton, Spacer } from './Toast.styles';

interface ToastBlenderProps {
  variant: Variant;
  tone: Tone;
}

export interface ToastProps extends StylableComponentProps, Partial<ToastBlenderProps> {
  /** Custom Icon (optional) **/
  icon?: ReactNode;
  /** Primary actions component **/
  action?: ReactNode;
  children: ReactNode;
  onClose?: () => void;
  isClosable?: boolean;
  'data-test-specifier'?: string;
}

const iconByVariant: Record<Variant, ReactNode> = {
  primary: <Info />,
  secondary: <Info />,
  error: <Error />,
  warning: <Warning />,
  success: <Check />,
  overlay: <Info />,
};


const toBlenderProps = ({ variant, tone }: ToastBlenderProps): BlenderProps => ({
  variant,
  mode: tone === 'solid' ? 'solidContrast' : 'surfaceContrast',
  intensity: tone === 'solid' ? 'md' : 'sm',
});

export const Toast = asStyledComponent<FC<ToastProps>>(({
  icon,
  variant = 'primary',
  tone = 'solid',
  action,
  onClose,
  isClosable = true,
  className,
  children,
  ...rest
}) => {
    return (
      <SurfaceContextProvider variant={variant} tone={tone}>
        <FoundationContextProvider size="sm">
          <ToastRoot
            className={className}
            {...toBlenderProps({ variant, tone })}
            data-test="toast"
            {...rest}
          >
            <FoundationContextProvider size="lg">{icon !== undefined ? icon : iconByVariant[variant]}</FoundationContextProvider>
            <ToastContent>{children}</ToastContent>
            <Spacer />
            {action}
            {isClosable && <CloseButton onClick={onClose} leftSlot={<Cross />} data-test="toast--close-button" />}
          </ToastRoot>
        </FoundationContextProvider>
      </SurfaceContextProvider>
    );
  }
);
