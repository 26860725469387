import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Toast } from '@cld/toast';
import { Fade } from '@cld/transitions';
import { SnackbarProps } from './SnackbarProvider';

export interface SnackbarsWrapperProps {
  children: ReactNode;
}

const SnackbarsContainerStyled = styled.div`
  position: relative;
`;
const SnackbarsWrapperStyled = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${globalTheme.spacing.xl};
  width: 480px;
  max-width: calc(100% - 2.5rem);
  margin: auto;
  z-index: 9001;
  overflow-wrap: break-word;

  > ${Fade} {
    margin-top: ${globalTheme.spacing.sm};
    border-radius: ${globalTheme.radius.md};
    box-shadow: ${globalTheme.shadow.md};
  }
`;

export const SnackbarsWrapper = ({ children }: SnackbarsWrapperProps) => (
  <SnackbarsContainerStyled>
    <SnackbarsWrapperStyled>{children}</SnackbarsWrapperStyled>
  </SnackbarsContainerStyled>
);

export const Snackbar = ({ message, ...rest }: SnackbarProps) => (
  <Fade in>
    <Toast {...rest}>{message}</Toast>
  </Fade>
);
