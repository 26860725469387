import React, { FC } from 'react';
import { ListSelectionProvider, Unselect } from '@cld/compound-components';
import { ChildDataRegistryProvider, useChildRegistry } from './childData';

export interface MenuProviderProps {
  focusedId?: string | Unselect;
  selectedId?: string | Unselect;
  registry: ReturnType<typeof useChildRegistry>;
  select: (id: string | Unselect) => void;
}

export const MenuProvider: FC<MenuProviderProps> = ({ children, registry, select, focusedId, selectedId }) => (
  <ChildDataRegistryProvider value={registry}>
    <ListSelectionProvider onClick={select} focusedId={focusedId} selectedId={selectedId}>
      {children}
    </ListSelectionProvider>
  </ChildDataRegistryProvider>
);
