import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { combineValues, globalTheme } from '@clds/component-theme';
import { appsTheme as typographyTheme, typographyMixin } from '@clds/typography';
import { focusVisible } from '@clds/style-utils';
import { supportFieldLineLayout } from '@clds/toolbar-layout';
import { TextWrap } from '@cld/component-enhancer';
import { Icon, iconMixin } from '@cld/icon';
import { buttonTheme } from '../buttonTheme';
import { StyledProgress } from './StyledProgress';

//space that added in both sides to make the button a rectangle (when not in "icon only" mode)
const calcRectangleMarginBySize = ({ size, ...props }) => {
  switch (size) {
    case 'sm':
      return globalTheme.spacing.xxs(props);
    case 'md':
      return globalTheme.spacing.xs(props);
    case 'lg':
      return globalTheme.spacing.sm(props);
    default:
      return '';
  }
};

const focusCss = ({ shape, buttonType }) => {
  const boxShadowStyles = (shape && buttonType) ? css`
    box-shadow: 0 0 0 3px ${combineValues(buttonTheme[shape]?.[buttonType]?.focus?.color, (color) => rgba(color, 0.5))};
  ` : '';

  const borderStyles = (shape && buttonType) ? css`
    border-radius: ${buttonTheme[shape]?.[buttonType]?.border?.radius};
    border: 1px solid ${buttonTheme[shape]?.[buttonType]?.focus?.color};
  ` : '';

  return css`
    &${focusVisible()} {
      ${boxShadowStyles};
  
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        ${borderStyles};
      }
    }
  `;
};

const shapeCss = ({ shape, buttonType }) => css`
  ${() => shape && buttonType && css`
    border-radius: ${buttonTheme[shape]?.[buttonType]?.border?.radius};
  `};
  ${supportFieldLineLayout()};
`;

const styledBaseButtonCss = ({ size, ...props }) => {
  const { shape, buttonType } = props;
  const typographyType = buttonTheme[shape]?.[buttonType]?.typography?.type(props);

  const fontSize = typographyTheme[{ sm: 'xs', md: 'md', lg: 'lg' }[size]].size(props);
  const padding = globalTheme.spacing[{ sm: 'xs', md: 'sm', lg: 'md' }[size]](props);
  const typographyStyle = typographyMixin({ sm: 'xs', md: 'md', lg: 'lg' }[size], typographyType);

  return css`
    padding: ${padding};
    ${typographyStyle};
    height: calc(${fontSize} + ${padding} + ${padding});
    min-width: calc(${fontSize} + ${padding} + ${padding});

    ${Icon} {
      ${iconMixin}
    }
  `;
};

const disabledCss = css`
  :disabled {
    pointer-events: none;
    opacity: 0.25;
  }
`;

const clickCss = css`
  cursor: pointer;
`;

export const StyledBaseButton = styled.button`
  position: relative;
  display: inline-flex;
  /* fix for safari improperly measuring children height */
  min-height: -webkit-fit-content;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: none;
  outline: none;
  font-weight: 500;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: ${({ progress }) => progress ? 'none' : 'auto'};
  background-color: transparent;
  background-clip: padding-box;

  ${styledBaseButtonCss};

  transition: background-color 350ms, color 350ms, box-shadow 250ms, opacity 250ms;

  svg path {
    transition: fill 350ms, stroke 350ms;
  }

  ${Icon}, ${TextWrap} {
    &:not(${StyledProgress}) {
      visibility: ${({ progress }) => progress ? 'hidden' : 'inherit'};

      &:not(:first-child) {
        margin-left: ${globalTheme.spacing.xs}; //space between elements
      }

      &:first-child {
        margin-left: ${calcRectangleMarginBySize};
      }

      &:last-child {
        margin-right: ${calcRectangleMarginBySize};
      }
    }
  }

  > ${StyledProgress}${StyledProgress}${StyledProgress} {
    //we need to be more specific than the style above
    & + ${Icon}:last-child {
      margin: 0;
    }

    & + * {
      margin-left: ${calcRectangleMarginBySize};
    }
  }

  ${Icon}${Icon}:disabled {
    margin: 0;
  }

  ${Icon}${Icon}:only-child {
    margin: 0;
  }

  ${({ selected, disabled }) =>
    !selected && !disabled && css`
      ${clickCss};
      ${focusCss};
  `};
  ${({ disabled }) => disabled && disabledCss}
  ${shapeCss};
`;
