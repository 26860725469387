import React, { FC, useLayoutEffect } from 'react';
import { useUniqueId } from '@cld/compound-components';
import { MenuItem, MenuItemProps } from '@cld/menu';
import { useDropdownMenuContext } from './DropdownMenuContext';

const useRegisterNonClosableMenuItem = (id: string) => {
  const { registerNonClosableMenuItem, unregisterNonClosableMenuItem } = useDropdownMenuContext();

  useLayoutEffect(() => {
    registerNonClosableMenuItem(id);
    return () => unregisterNonClosableMenuItem(id);
  }, [id, registerNonClosableMenuItem, unregisterNonClosableMenuItem]);
};

export interface NonClosableMenuItemProps extends MenuItemProps {
  id?: string;
}

export const NonClosableMenuItem: FC<NonClosableMenuItemProps> = ({ id: idProvided, ...rest }) => {
  const id = useUniqueId(idProvided);
  useRegisterNonClosableMenuItem(id);

  return <MenuItem id={id} {...rest} />;
};
