import { useCallback, useReducer } from 'react';

import { Environment } from '@cld/console-apps-services';
import { analyticsMiddleware, TrackingFunction } from './effects/analyticsMiddleware';
import { reducer } from './reducer';
import { initialState } from './state';
import { useVideoInfoFetch } from './effects/useVideoInfoFetch';
import { useVideoDownload } from './effects/useVideoDownload';
import { useVideoPolling } from './effects/useVideoPolling';
import { useVideoUpload } from './effects/useVideoUpload';
import { useVideoPreview } from './effects/useVideoPreview';
import { usePollingTextRotation } from './effects/usePollingTextRotation';
import { Action } from './actions';

export const useStateManagement = ({ clock, track }: { clock: () => Date; track: TrackingFunction }) => {
  const [state, rawDispatch] = useReducer(reducer, initialState);

  const dispatch = useCallback(
    (action: Action) => {
      log(action);
      rawDispatch(action);
      analyticsMiddleware(track, action, state);
    },
    [rawDispatch, track, state]
  );

  usePollingTextRotation(dispatch, state.videoPolling);
  useVideoInfoFetch(dispatch, state.videoInfoFetch);
  useVideoDownload(dispatch, state.videoDownload);
  useVideoPreview(dispatch, clock, state.videoPreview);
  useVideoPolling(dispatch, clock, state.videoPolling);
  useVideoUpload(dispatch, clock, state.videoUpload);

  return { dispatch, state };
};

const log = (action: Action) => {
  if (!Environment.Production) {
    console.log(action.type, action.payload);
  }
};
